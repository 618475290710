import firebase from "firebase/app";
import globalStore from "./GlobalStore";
import moment from "moment-timezone";

const Tailor = async (messageID, data = {}) => {
	let apiURL;

	if (process.env.REACT_APP_ENV === "staging") {
		apiURL = "https://api.staging.myhealthyday.com/mhd/2.0/tailor";
	} else {
		apiURL =
			process.env.NODE_ENV === "development"
				? "http://localhost:3000/mhd/2.0/tailor"
				: "https://api.myhealthyday.com/mhd/2.0/tailor";
	}

	data.apiKey = "nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D";
				data.returnElementSlugs = true;
	data.userTimezone = moment.tz.guess();

	let firebasetoken = firebase.auth().currentUser
		? await firebase.auth().currentUser.getIdToken(false)
		: "";

	const unParsedData = await fetch(`${apiURL}/${messageID}`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=utf-8",
			firebasetoken,
			currentDevice: globalStore.currentDevice
		},
		body: JSON.stringify(data)
	});

	return await unParsedData.json();
};

export { Tailor };
