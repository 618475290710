import React, { Component } from "react";
import { CardHeader, Col, Row } from "reactstrap";
import TooltipItem from "../../Tooltip";
import API from "../../API";
import { Checkbox } from "@rmwc/checkbox";
import { Card } from "@rmwc/card";

export default class ToDos extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		try {
			const toDos = JSON.parse(this.props.tailorContent.toDosList);
			this.setState({ toDos });
			API(
				`/getData/${toDos
					.map(toDo => {
						return toDo.value;
					})
					.join(",")}`,
				"POST",
				{},
				toDoData => {
					let toSet = {};
					for (const dataPoint of toDoData.data) {
						toSet[[dataPoint.keyCol]] = dataPoint.valueCol === "true";
					}

					this.setState(toSet);
				}
			);
		} catch (e) {
			console.error("Error parsing todos");
		}
	}

	render() {
		return (
			<Card
				style={{
					marginTop: 10,
					marginBottom: 10,
					border: 0,
					borderRadius: 10,
					...this.props.style
				}}
			>
				<CardHeader
					style={{
						backgroundColor: "#1E487FCC",
						color: "white",
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10
					}}
				>
					To Dos
					<TooltipItem
						id={"toDosTooltip"}
						text={this.props.tailorContent.toDosReinforcement}
					/>
				</CardHeader>
				<div style={{ padding: 10 }}>
					<div>Click an item to mark it as completed.</div>
					{this.state.toDos ? (
						<div>
							{this.state.toDos.map((toDo, index) => {
								return (
									<Row
										key={index}
										style={{
											alignItems: "flex-start",
											marginBottom: 5,
											marginTop: 5
										}}
									>
										<Col>
											<Checkbox
												key={toDo.value}
												// style={{ display: "block" }}
												checked={this.state[toDo.value] || false}
												onChange={evt => {
													this.setState({
														[toDo.value]: evt.target.checked
													});
													API(
														"/data",
														"PUT",
														{
															data: {
																[toDo.value]: evt.target.checked
																	? "true"
																	: "false"
															}
														},
														() => {}
													);
												}}
												label={
													<label
														style={{
															textDecoration: this.state[toDo.value]
																? "line-through"
																: "none"
														}}
													>
														{toDo.label}
													</label>
												}
											/>
										</Col>
									</Row>
								);
							})}
						</div>
					) : null}
				</div>
			</Card>
		);
	}
}
