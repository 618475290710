import React, { Component } from "react";
import autoBind from "react-autobind";
import { Redirect } from "react-router-dom";
import moment from "moment-timezone";
import API from "./API";

export default class Demographics extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = { zipcode: "", dob: "" };
	}

	save() {
		if (isNaN(this.state.zipcode) || this.state.zipcode.length !== 5) {
			alert("Invalid zipcode");
		} else if (!moment(this.state.dob).isValid()) {
			alert("Invalid DOB");
		} else {
			API(
				"/data",
				"PUT",
				{
					data: {
						dateOfBirth: moment(this.state.dob),
						zipcode: this.state.zipcode
					}
				},
				() => {
					this.setState({ redirect: "/sessionIntroduction" });
				}
			);
		}
	}

	render() {
		return this.state.redirect ? (
			<Redirect to={this.state.redirect} />
		) : (
			<div>
				<label>Zip:</label>
				<input
					type="text"
					value={this.state.zipcode}
					onChange={evt => {
						this.setState({ zipcode: evt.target.value });
					}}
					className="form-control"
				/>
				<br />
				<label>DOB:</label>
				<input
					type="date"
					value={this.state.dob}
					className="form-control"
					onChange={evt => {
						this.setState({ dob: evt.target.value });
					}}
				/>
				<br />
				<button className="btn btn-primary" onClick={this.save}>
					Continue
				</button>
			</div>
		);
	}
}
