import { observer } from "mobx-react";
import { observable } from "mobx";
import React, { Component } from "react";
import autoBind from "react-autobind";
import "rc-slider/assets/index.css";
import API from "./API";
import Loading from "./Loading";
import {
	VictoryBar,
	VictoryChart,
	VictoryTheme,
	VictoryAxis,
	VictoryLabel
} from "victory";
import wrapAnsi from "wrap-ansi";
import QuestionNavigation from "./Dashboard/Cards/QuestionNavigation";

const QuestionResults = observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);
			this.state = { results: null, notEnoughResults: null };

			this.pageStore = new observable({
				selectedAnswer: null,
				otherTextField: "",
				selectedAnswerDropdown: null
			});
		}

		componentDidMount() {
			API(`/questionResults/${this.props.attribute}`, "GET", {}, data => {
				if (data.notEnoughResults || !data.results) {
					this.setState({ notEnoughResults: true, results: false }, () => {});
				} else {
					this.setState({ results: data.results, notEnoughResults: false });
				}
			});
		}

		componentWillReceiveProps(nextProps) {
			if (
				nextProps.attributes !== this.props.attribute &&
				this.props.attribute
			) {
				this.setState({ results: null, notEnoughResults: null }, () => {
					API(`/questionResults/${this.props.attribute}`, "GET", {}, data => {
						if (data.notEnoughResults || !data.results) {
							this.setState(
								{ notEnoughResults: true, results: false },
								() => {}
							);
						} else {
							this.setState({ results: data.results, notEnoughResults: false });
						}
					});
				});
			}
		}

		generateResults = () => {
			let results = this.props.answers
				.map((answer, index) => {
					const result = Object.keys(this.state.results).find(key => {
						return key === answer.value;
					});

					return {
						axisLabel: wrapAnsi(answer.label, 12),
						votes: result ? Math.trunc(this.state.results[result] * 100) : 0,
						selected: result === this.props.answer,
						originalIndex: index
					};
				})
				.reverse();

			if (results.length > 8) {
				results = results.sort((a, b) => {
					return b.votes - a.votes;
				});

				const filteredResults = results.splice(0, 8).sort((a, b) => {
					return b.originalIndex - a.originalIndex;
				});

				let otherTotal = results.reduce((sum, result) => {
					return sum + result.votes;
				}, 0);

				const other = filteredResults.find(result => {
					return result.axisLabel === "Other";
				});
				if (other) {
					other.votes = other.votes + otherTotal;
				} else {
					filteredResults.push({
						axisLabel: "Other",
						votes: otherTotal,
						selected: false
					});
				}

				return filteredResults;
			} else {
				return results;
			}
		};

		render() {
			return (
				<div className="justify-content-center">
					{this.state.results === null ||
					this.state.notEnoughResults === null ? (
						<Loading small />
					) : this.state.notEnoughResults || !this.props.answers ? (
						<div>
							<p>
								{this.props.tailorContent[`${this.props.type}ThankYouText`]}{" "}
								{this.props.tailorContent[`${this.props.type}NotEnoughResults`]}
							</p>
						</div>
					) : (
						<div>
							<p>{this.props.question}</p>
							<div>
								<VictoryChart
									theme={VictoryTheme.material}
									domainPadding={{ y: 10 }}
									padding={{ left: 75, top: 50, right: 50, bottom: 50 }}
								>
									<VictoryAxis dependentAxis />
									<VictoryBar
										x="axisLabel"
										y="votes"
										horizontal
										style={{
											data: {
												fill: t => {
													return t.selected ? "#A92D25" : "#1E487F";
												}
											},
											labels: { fill: "white" }
										}}
										animate={{ duration: 2000 }}
										data={!this.state.results ? {} : this.generateResults()}
										labels={d => {
											return d.votes < 10 ? null : `${d.votes}%`;
										}}
										labelComponent={<VictoryLabel dx={-40} />}
									/>
								</VictoryChart>
							</div>
						</div>
					)}
					<QuestionNavigation
						currentQuestionNum={this.props.questionNum}
						tailorContent={this.props.tailorContent}
						goForward={this.props.goForward}
						goBack={this.props.goBack}
						type={this.props.type}
					/>
				</div>
			);
		}
	}
);

export { QuestionResults };
