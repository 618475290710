import React from "react";
import { toJS, extendObservable } from "mobx";

export function envInfo() {
	if (process.env.REACT_APP_ENV === "staging") {
		return <span style={{ color: "red" }}>STAGING </span>;
	} else if (process.env.NODE_ENV === "development") {
		return <span style={{ color: "red" }}> LOCAL, DUMB ASS </span>;
	}
}

export function updateState(toUpdate, updatedData) {
	const toUpdateJS = toJS(toUpdate);

	if (
		Object.entries(toUpdateJS).length === 0 &&
		toUpdateJS.constructor === Object
	) {
		extendObservable(toUpdate, updatedData);
	} else {
		const updatedDataKeys = Object.keys(updatedData);
		const toUpdateKeys = Object.keys(toUpdateJS);
		for (const key of toUpdateKeys) {
			if (!updatedDataKeys.includes(key)) {
				delete toUpdate[key];
			}
		}

		for (const [key, value] of Object.entries(updatedData)) {
			toUpdate[key] = value;
		}
	}
}

export function emptyObj(obj) {
	const objToCheck = obj;

	return (
		Object.entries(objToCheck).length === 0 && objToCheck.constructor === Object
	);
}
