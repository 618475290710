import React, { Component } from "react";
import { CardHeader, Container } from "reactstrap";
import { QuestionResults } from "../../QuestionResults";
import { QuestionContainer } from "../../Question";
import TooltipItem from "../../Tooltip";
import Loading from "../../Loading";
import questionStore from "../Rows/QuestionStore";
import { observer } from "mobx-react";
import { Card } from "@rmwc/card";

export default observer(
	class Polls extends Component {
		constructor(props) {
			super(props);

			this.state = { currentQuestionNum: 1 };
		}

		setQuestionNumber = () => {
			const pollNumber = parseInt(questionStore.tailorContent.pollNumber, 10);

			for (let i = 1; i <= pollNumber; i++) {
				const attribute = questionStore.tailorContent[`pollAttribute_${i}`];

				if (!(attribute in questionStore.answers)) {
					if (i !== this.state.currentQuestionNum) {
						this.setState({
							currentQuestionNum: i,
							saving: false
						});
					}
					break;
				} else if (i === pollNumber) {
					this.setState({ saving: false });
				}
			}
		};

		componentWillMount() {
			this.setQuestionNumber();
		}

		save = (pageStore, attribute, answers, privateQuestion) => {
			this.setState({ saving: true });
			this.props.questionSave(pageStore, attribute, answers, privateQuestion);
		};

		goForward = () => {
			this.setState({
				currentQuestionNum: this.state.currentQuestionNum + 1,
				saving: false
			});
		};

		goBack = () => {
			this.setState({
				currentQuestionNum: this.state.currentQuestionNum - 1,
				saving: false
			});
		};

		questionSectionGenerator() {
			const attribute =
				questionStore.tailorContent[
					`pollAttribute_${this.state.currentQuestionNum}`
				];
			const question =
				questionStore.tailorContent[
					`pollQuestion_${this.state.currentQuestionNum}`
				];
			const answers = questionStore.tailorContent[
				`pollResponses_${this.state.currentQuestionNum}`
			]
				? JSON.parse(
						questionStore.tailorContent[
							`pollResponses_${this.state.currentQuestionNum}`
						]
				  )
				: null;

			if (attribute in questionStore.answers) {
				return (
					<Container style={{ padding: 10 }}>
						<QuestionResults
							tailorContent={questionStore.tailorContent}
							answer={questionStore.answers[attribute]}
							attribute={attribute}
							answers={answers}
							question={question}
							type="poll"
							questionNum={this.state.currentQuestionNum}
							goForward={this.goForward}
							goBack={this.goBack}
						/>
					</Container>
				);
			} else {
				return (
					<Container style={{ padding: 10 }}>
						{this.state.saving ? (
							<Loading small />
						) : (
							<QuestionContainer
								questionNum={this.state.currentQuestionNum}
								question={question}
								answers={answers}
								attribute={attribute}
								save={this.save}
								type="poll"
							/>
						)}
					</Container>
				);
			}
		}

		render() {
			let poll = this.questionSectionGenerator();

			return (
				<Card
					style={{
						marginTop: 10,
						marginBottom: 10,
						border: 0,
						borderRadius: 10
					}}
				>
					<CardHeader
						style={{
							backgroundColor: "#1E487FCC",
							color: "white",
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10
						}}
					>
						Polls
						<TooltipItem
							id={"pollsTooltip"}
							text={questionStore.tailorContent.pollReinforcement}
						/>
					</CardHeader>
					<div style={{ padding: 5, minHeight: 150 }}>
						{poll}
						{questionStore.tailorContent.pollNumber !== "1" ? (
							<div
								className="text-right"
								style={{
									color: "gray",
									fontSize: 12,
									paddingRight: 15
								}}
							>
								{this.state.currentQuestionNum}/
								{questionStore.tailorContent.pollNumber}
							</div>
						) : null}
					</div>
				</Card>
			);
		}
	}
);
