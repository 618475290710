import React, { Component } from "react";
import { CardHeader } from "reactstrap";
import TooltipItem from "../../Tooltip";
import Thumbs from "../../Thumbs";
import { Card } from "@rmwc/card";

export default class Tidbits extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	tailorData(key) {
		if (this.props.tailorContent) {
			return (
				<span
					dangerouslySetInnerHTML={{ __html: this.props.tailorContent[key] }}
				/>
			);
		} else {
			return <span />;
		}
	}

	render() {
		return (
			<Card
				style={{
					marginTop: 10,
					marginBottom: 10,
					border: 0,
					borderRadius: 10,
					...this.props.style
				}}
			>
				<CardHeader
					style={{
						backgroundColor: "#1E487FCC",
						color: "white",
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10
					}}
				>
					Tidbits
					<TooltipItem
						id={"tidbitsTooltip"}
						text={this.props.tailorContent.tidbitsReinforcement}
					/>
				</CardHeader>
				<div
					style={{
						padding: 10,
						borderBottom: "1px solid lightGray"
					}}
					className="checkInButton"
				>
					<div>{this.tailorData("tidbits_1")}</div>

					<Thumbs type="tidbits" number={1} />
				</div>
				<div
					style={{ padding: 10, borderBottom: "1px solid lightGray" }}
					className="checkInButton"
				>
					<div>{this.tailorData("tidbits_2")}</div>

					<Thumbs type="tidbits" number={2} />
				</div>
				<div style={{ padding: 10 }} className="checkInButton">
					<div>{this.tailorData("tidbits_3")}</div>

					<Thumbs type="tidbits" number={3} />
				</div>
			</Card>
		);
	}
}
