import { observable } from "mobx";
import bowser from "bowser";

let currentDevice = "other";
if (bowser.ios) {
	currentDevice = "webMobileIOs";
} else if (bowser.android) {
	currentDevice = "webMobileAndroid";
} else if (bowser.chrome) {
	if (bowser.mac) {
		currentDevice = "webDesktopChromeMac";
	} else if (bowser.windows) {
		currentDevice = "webDesktopChromeWindows";
	} else {
		currentDevice = "webDesktopChromeUnknownOS";
	}
} else if (bowser.firefox) {
	if (bowser.mac) {
		currentDevice = "webDesktopFirefoxMac";
	} else if (bowser.windows) {
		currentDevice = "webDesktopFirefoxWindows";
	} else {
		currentDevice = "webDesktopFirefoxUnknownOS";
	}
} else if (bowser.msedge) {
	currentDevice = "webDesktopEdgeWindows";
} else if (bowser.safari) {
	currentDevice = "webDesktopSafariMac";
}

const globalStore = new observable({
	currentDevice,
	deviceConnectionsOpen: false,
	remindersOpen: false,
	changePasswordOpen: false,
	jaimePersonalityOpen: false,
	headerTitle: null,
	headerProgress: null
});

export default globalStore;
