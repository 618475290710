import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import QuestionOfTheDay from "../Cards/QuestionOfTheDay";
import Polls from "../Cards/Polls";
import PrivateQuestions from "../Cards/PrivateQuestions";
import { observer } from "mobx-react";
import dashboardStore from "../DashboardPageStore";
import { autorun } from "mobx";
import API from "../../API";
import Loading from "../../Loading";
import questionStore from "./QuestionStore";
import { emptyObj, updateState } from "../../HelperFunctions";
import { Card } from "@rmwc/card";
import { Ripple } from "@rmwc/ripple";

export default observer(
	class TellUsMore extends Component {
		constructor(props) {
			super(props);

			this.state = {};

			autorun(() => {
				if (dashboardStore.expandedSection === "tellUsMore") {
					API(`/dashboardWeb/tellUsMore`, "GET", {}, data => {
						updateState(questionStore.tailorContent, data.tailorContent);
						updateState(questionStore.answers, data.answers);
						window.scrollTo({
							left: 0,
							top: this.container.offsetTop - 65,
							behavior: "smooth"
						});
					});
				} else {
					updateState(questionStore.tailorContent, {});
				}
			});
		}

		loadNextQuestion = () => {
			API(`/dashboardWeb/tellUsMore`, "GET", {}, data => {
				updateState(questionStore.tailorContent, data.tailorContent);
				updateState(questionStore.answers, data.answers);
			});
		};

		questionSave = (pageStore, attribute, answers, privateQuestion) => {
			let dataToSend = {};
			if (pageStore.checkedBoxes.length > 0) {
				for (const value of Object.values(answers[0].checkboxes)) {
					dataToSend[value.value] = pageStore.checkedBoxes.includes(value.value)
						? "checked"
						: "unchecked";
				}
			} else {
				const answer = pageStore.selectedAnswer
					? pageStore.selectedAnswer
					: pageStore.otherTextField
					? pageStore.otherTextField
					: "";

				dataToSend = { [attribute]: answer };
			}

			API(
				"/data",
				"PUT",
				{
					data: dataToSend,
					aggregatable: !privateQuestion
				},
				() => {
					pageStore.selectedAnswer = null;
					pageStore.otherTextField = null;
					pageStore.selectedAnswerDropdown = null;
					pageStore.checkedBoxes = [];

					this.loadNextQuestion();
				}
			);
		};

		render() {
			return (
				<Card
					style={{
						border: 0,
						marginTop: 10,
						marginBottom: 10,
						borderRadius: 10
					}}
				>
					<Ripple
						onClick={() => {
							if (dashboardStore.expandedSection === "tellUsMore") {
								dashboardStore.expandedSection = null;
							} else {
								dashboardStore.expandedSection = "tellUsMore";
							}
						}}
					>
						<div
							ref={ref => {
								this.container = ref;
							}}
							style={{
								paddingTop: 12,
								paddingBottom: 12,
								paddingRight: 20,
								paddingLeft: 20,
								backgroundColor: "#1e487f",
								cursor: "pointer",
								color: "white",
								borderRadius: 10,
								borderBottomLeftRadius:
									dashboardStore.expandedSection === "tellUsMore" ? 0 : 10,
								borderBottomRightRadius:
									dashboardStore.expandedSection === "tellUsMore" ? 0 : 10
							}}
						>
							Tell Us More...
							{dashboardStore.expandedSection === "tellUsMore" ? (
								<div className="float-right">
									<FontAwesomeIcon icon={faChevronUp} color="white" />
								</div>
							) : (
								<div className="float-right">
									<FontAwesomeIcon icon={faChevronDown} color="white" />
								</div>
							)}
						</div>
					</Ripple>
					{dashboardStore.expandedSection === "tellUsMore" ? (
						<div>
							{!emptyObj(questionStore.tailorContent) ? (
								<Row style={{ paddingLeft: 20, paddingRight: 20 }}>
									<Col xs={12} lg={4}>
										<PrivateQuestions
											loadNextQuestion={this.loadNextQuestion}
											questionSave={this.questionSave}
										/>
									</Col>
									<Col xs={12} lg={4}>
										<QuestionOfTheDay
											loadNextQuestion={this.loadNextQuestion}
											questionSave={this.questionSave}
										/>
									</Col>
									<Col xs={12} lg={4}>
										<Polls
											loadNextQuestion={this.loadNextQuestion}
											questionSave={this.questionSave}
										/>
									</Col>
								</Row>
							) : (
								<Loading />
							)}
						</div>
					) : null}
				</Card>
			);
		}
	}
);
