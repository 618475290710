import React, { Component } from "react";
import autoBind from "react-autobind";
import * as firebase from "firebase/app";
import API from "./API";
import "firebase/auth";
import { Col, Row, Container } from "reactstrap";
import moment from "moment-timezone";
import { Button } from "@rmwc/button";
import { Card } from "@rmwc/card";
import { TextField } from "@rmwc/textfield";
import bowser from "bowser";

export default class Login extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			email: "",
			password: "",
			existingUser: null,
			errorMessage: null,
			buttonText: "Continue",
			dob: "",
			firstName: "",
			lastName: "",
			zipCode: "",
			validDate: true
		};
	}

	componentDidMount() {}

	handleChange(event) {
		const name = event.target.name;
		const value = event.target.value;
		this.setState({
			[name]: value
		});
	}

	handleChangeDate = evt => {
		this.setState({ dob: evt.target.value });
	};

	dateBlur = evt => {
		if (bowser.safari && !bowser.ios) {
			evt.target.value = evt.target.value.replace(/-/g, "/").replace(/\s/g, "");
			const dateParsed = moment(evt.target.value).format("L");
			const yearStart = dateParsed.substring(6, 8);

			if (yearStart === "19" || yearStart === "20") {
				this.setState({
					validDate: true,
					dob: moment(evt.target.value, "MM/DD/YYYY").format("L")
				});
			} else {
				this.setState({ validDate: false });
			}
		}
	};

	signIn = async event => {
		event.preventDefault();
		if (this.state.buttonText === "Continue") {
			API(
				"/accountCheck",
				"POST",
				{ email: this.state.email },
				data => {
					if (data.existing) {
						this.setState({ existingUser: true, buttonText: "Sign In" });
						this.passwordInput.focus();
					} else if (data.newAccount) {
						this.setState({ existingUser: false, buttonText: "Sign Up" });
						this.passwordInput.focus();
					} else if (data.unauthorized) {
						this.setState({
							errorMessage: "This email address is not authorized to use MHD."
						});
					}
				},
				false
			);
		} else if (this.state.buttonText === "Sign Up") {
			if (!this.state["firstName"]) {
				this.setState({ errorMessage: "Please enter your first name." });
			} else if (!this.state["lastName"]) {
				this.setState({ errorMessage: "Please enter your last name." });
			} else if (!this.state["zipCode"]) {
				this.setState({ errorMessage: "Please enter your zip code." });
			} else if (!this.state["dob"]) {
				this.setState({ errorMessage: "Please enter your Date of Birth." });
			} else if (
				!moment(this.state["dob"]) ||
				!moment(this.state["dob"]).isValid()
			) {
				this.setState({
					errorMessage: "Please enter your Date of Birth in mm/dd/yyyy format."
				});
			} else {
				API(
					"/signup",
					"POST",
					{
						zipCode: this.state.zipCode,
						dob: this.state.dob,
						firstName: this.state.firstName,
						lastName: this.state.lastName,
						password: this.state.password,
						email: this.state.email
					},
					data => {
						if (data.unauthorized) {
							this.setState({
								errorMessage: "This email address is not authorized to use MHD."
							});
						} else {
							firebase
								.auth()
								.signInWithEmailAndPassword(
									this.state.email,
									this.state.password
								)
								.then(() => {
									//do nothing this is handled in onAuthStateChanged
								})
								.catch(error => this.setState({ errorMessage: error.message }));
						}
					},
					false
				);
			}
		} else {
			API(
				"/accountCheck",
				"POST",
				{ email: this.state.email },
				data => {
					if (data.unauthorized) {
						this.setState({
							errorMessage: "This email address is not authorized to use MHD."
						});
					} else {
						firebase
							.auth()
							.signInWithEmailAndPassword(this.state.email, this.state.password)
							.then(async () => {
								//routing is handled in the master routing function in App.jsx
							})
							.catch(error => this.setState({ errorMessage: error.message }));
					}
				},
				false
			);
		}
	};

	forgotPassword = () => {
		if (!this.state.email) {
			this.setState({ errorMessage: "Please enter an email address to reset" });
		} else {
			firebase
				.auth()
				.sendPasswordResetEmail(this.state.email, {
					url: "https://myhealthyday.page.link/app"
				})
				.then(() => {
					this.setState({
						errorMessage: "Please check your email for a password reset link."
					});
				})
				.catch(error => this.setState({ errorMessage: error.message }));
		}
	};

	render() {
		return (
			<Container>
				<Card className="loginCard">
					<Row>
						<Col
							xs={{ size: 8, offset: 2 }}
							sm={{ size: 6, offset: 3 }}
							className="text-center"
						>
							<img
								src="/MHDLogoDark.png"
								alt="MyHealthyDay logo"
								style={{ maxWidth: 300, width: "90%" }}
							/>
						</Col>
					</Row>

					<Row style={{ marginTop: 25 }}>
						<Col xs={{ size: 12 }} sm={{ size: 8, offset: 2 }}>
							{this.state.errorMessage && (
								<p className="errorText text-center">
									{this.state.errorMessage}
								</p>
							)}

							<form onSubmit={this.signIn}>
								<TextField
									outlined
									type="text"
									name="email"
									label="Email address"
									value={this.state.email}
									onChange={this.handleChange}
									required={true}
									autoComplete="username email"
								/>

								{this.state.existingUser !== null ? (
									<TextField
										outlined
										type="password"
										name="password"
										label="Password"
										value={this.state.password}
										onChange={this.handleChange}
										required
										inputRef={input => {
											this.passwordInput = input;
										}}
										autoComplete={
											this.state.existingUser === false
												? "new-password"
												: "current-password"
										}
									/>
								) : null}
								{this.state.existingUser === false ? (
									<div>
										<TextField
											outlined
											type="text"
											name="firstName"
											label="First Name"
											value={this.state.firstName}
											onChange={this.handleChange}
											required={true}
										/>
										<TextField
											outlined
											type="text"
											name="lastName"
											label="Last Name"
											value={this.state.lastName}
											onChange={this.handleChange}
											required={true}
										/>
										<TextField
											outlined
											name="zipCode"
											type="number"
											label="Zip Code"
											value={this.state.zipCode}
											onChange={this.handleChange}
											required={true}
										/>
										<TextField
											outlined
											name="dob"
											type="date"
											label="Date of Birth"
											value={this.state.dob}
											onChange={this.handleChangeDate}
											required={true}
											helpText={bowser.safari ? "mm/dd/yyyy" : ""}
											onBlur={this.dateBlur}
											invalid={!this.state.validDate}
											format="mm/dd/yyyy"
										/>
									</div>
								) : null}

								<Button
									raised
									onClick={this.signIn}
									style={{ marginTop: 15, marginBottom: 15 }}
								>
									{this.state.buttonText}
								</Button>
								<Row>
									<Col className="text-right">
										<a
											style={{ cursor: "pointer", fontSize: 12 }}
											onClick={this.forgotPassword}
										>
											Forgot password
										</a>
									</Col>
								</Row>
							</form>
						</Col>
					</Row>
				</Card>
			</Container>
		);
	}
}
