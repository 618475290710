import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import ToDos from "../Cards/ToDos";
import Goals from "../Cards/Goals";
import DailyRundown from "../Cards/DailyRundown";
import { observer } from "mobx-react";
import dashboardStore from "../DashboardPageStore";
import { autorun } from "mobx";
import API from "../../API";
import Loading from "../../Loading";
import { Ripple } from "@rmwc/ripple";
import { Card } from "@rmwc/card";

export default observer(
	class MyProgress extends Component {
		constructor(props) {
			super(props);

			this.state = { tailorContent: null };

			autorun(() => {
				if (dashboardStore.expandedSection === "myProgress") {
					API(`/dashboardWeb/myProgress`, "GET", {}, data => {
						this.setState({ tailorContent: data.tailorContent }, () => {
							window.scrollTo({
								left: 0,
								top: this.container.offsetTop - 65,
								behavior: "smooth"
							});
						});
					});
				}
			});
		}

		render() {
			return (
				<Card
					style={{
						border: 0,
						marginTop: 10,
						marginBottom: 10,
						borderRadius: 10
					}}
				>
					<Ripple
						onClick={() => {
							if (dashboardStore.expandedSection === "myProgress") {
								dashboardStore.expandedSection = null;
							} else {
								dashboardStore.expandedSection = "myProgress";
							}
						}}
					>
						<div
							ref={ref => {
								this.container = ref;
							}}
							style={{
								paddingTop: 12,
								paddingBottom: 12,
								paddingRight: 20,
								paddingLeft: 20,
								backgroundColor: "#1e487f",
								fontWeight: 400,
								cursor: "pointer",
								color: "white",
								borderRadius: 10,
								borderBottomLeftRadius:
									dashboardStore.expandedSection === "myProgress" ? 0 : 10,
								borderBottomRightRadius:
									dashboardStore.expandedSection === "myProgress" ? 0 : 10
							}}
						>
							My Progress
							{dashboardStore.expandedSection === "myProgress" ? (
								<div className="float-right">
									<FontAwesomeIcon icon={faChevronUp} color="white" />
								</div>
							) : (
								<div className="float-right">
									<FontAwesomeIcon icon={faChevronDown} color="white" />
								</div>
							)}
						</div>
					</Ripple>
					{dashboardStore.expandedSection === "myProgress" ? (
						this.state.tailorContent ? (
							<Row style={{ paddingLeft: 20, paddingRight: 20 }}>
								<Col xs={12} lg={4}>
									<Goals tailorContent={this.state.tailorContent} />{" "}
								</Col>
								<Col xs={12} lg={4}>
									<ToDos tailorContent={this.state.tailorContent} />{" "}
								</Col>
								<Col xs={12} lg={4}>
									<DailyRundown tailorContent={this.state.tailorContent} />{" "}
								</Col>
							</Row>
						) : (
							<Loading />
						)
					) : null}
				</Card>
			);
		}
	}
);
