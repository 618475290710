import firebase from "firebase/app";
import globalStore from "./GlobalStore";
import moment from "moment-timezone";

const API = async (url, method, data, resultFunction, tokenRequired = true) => {
	let apiURL;

	if (process.env.REACT_APP_ENV === "staging") {
		apiURL = "https://api.staging.myhealthyday.com/mhd/2.0";
	} else {
		apiURL =
			process.env.NODE_ENV === "development"
				? "http://localhost:3000/mhd/2.0"
				: "https://api.myhealthyday.com/mhd/2.0";
	}
	let firebasetoken = firebase.auth().currentUser
		? await firebase.auth().currentUser.getIdToken()
		: "";

	function makeAPICall() {
		try {
			fetch(apiURL + url, {
				method,
				credentials: "include",
				mode: "cors",
				headers: {
					"Content-Type": "application/json",
					firebasetoken,
					currentdevice: globalStore.currentDevice,
					usertimezone: moment.tz.guess()
				},
				body:
					method === "GET" ? null : data === null ? null : JSON.stringify(data)
			})
				.then(function(response) {
					return response.json();
				})
				.then(resultFunction)
				.catch(function(ex) {
					console.log(apiURL + url);
					console.log("parsing failed");
					console.error(ex);
				});
		} catch (e) {
			alert("Something went wrong with the API call. Please report this bug.");
			console.error(e);
		}
	}

	if (tokenRequired && (!firebasetoken || firebasetoken === "")) {
		let runCounter = 0;

		const interval = setInterval(async () => {
			firebasetoken = firebase.auth().currentUser
				? await firebase.auth().currentUser.getIdToken()
				: "";
			if (firebasetoken) {
				clearInterval(interval);
				makeAPICall();
			} else {
				runCounter++;

				if (runCounter > 25) {
					clearInterval(interval);
					console.log(`API call fail w/auth to ${apiURL}${url}`);
				}
			}
		}, 250);
	} else {
		makeAPICall();
	}
};

export default API;
