import React, { Component } from "react";
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ListGroup,
	ListGroupItem,
	Row,
	Col
} from "reactstrap";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import globalStore from "../GlobalStore";
import API from "../API";
import moment from "moment-timezone";
import Loading from "../Loading";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import Select from "react-select";
import camelcase from "camel-case";
import titlecase from "title-case";
import { Checkbox } from "@rmwc/checkbox";

export default observer(
	class Reminders extends Component {
		constructor(props) {
			super(props);

			this.state = { remindersLoaded: false };
		}

		componentDidMount() {
			autorun(() => {
				if (globalStore.remindersOpen && !this.state.remindersLoaded) {
					API("/reminders", "GET", {}, data => {
						this.setState({ ...data.reminder, remindersLoaded: true });
					});
				}
			});
		}

		closeModal = event => {
			globalStore.remindersOpen = false;
			this.setState({ remindersLoaded: false });
		};

		changeTime = date => {
			this.setState({
				selectedTime: date
			});
		};

		save = () => {
			API(
				"/reminders",
				"PUT",
				{
					reminder: {
						alexaReminder: this.state.alexaReminder,
						count: this.state.count,
						emailReminder: this.state.emailReminder,
						googleHomeReminder: this.state.googleHomeReminder,
						id: this.state.id,
						lastSend: this.state.lastSend,
						pushReminder: this.state.pushReminder,
						reminderFrequency: this.state.reminderFrequency,
						selectedTime: this.state.selectedTime
					}
				},
				() => {
					globalStore.remindersOpen = false;
				}
			);
		};

		render() {
			return (
				<Modal isOpen={globalStore.remindersOpen} className="reminderSettings">
					<ModalHeader toggle={this.closeModal}>Reminder Settings</ModalHeader>
					{this.state.remindersLoaded ? (
						<ModalBody>
							<ListGroup>
								<ListGroupItem>
									<Row>
										<Col>Frequency</Col>
										<Col>
											<Select
												options={[
													"Daily",
													"Every Other Day",
													"Every Three Days",
													"Weekly",
													"Off"
												].map(option => {
													return { label: option, value: camelcase(option) };
												})}
												value={{
													value: this.state.reminderFrequency,
													label: titlecase(this.state.reminderFrequency, " ")
												}}
												onChange={option => {
													this.setState({ reminderFrequency: option.value });
												}}
											/>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col>Type</Col>
										<Col>
											<Checkbox
												checked={this.state.emailReminder || false}
												onChange={evt => {
													this.setState({
														emailReminder: evt.target.checked
													});
												}}
												label="Email"
											/>
											<Checkbox
												checked={this.state.pushReminder || false}
												onChange={evt => {
													this.setState({
														pushReminder: evt.target.checked
													});
												}}
												label="Push Notification"
											/>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col>Time</Col>
										<Col>
											<TimePicker
												value={moment(this.state.selectedTime, "HH:mm")}
												showSecond={false}
												minuteStep={15}
												use12Hours={true}
												format="h:mm a"
												allowEmpty={false}
												onChange={this.changeTime}
											/>
										</Col>
									</Row>
								</ListGroupItem>
							</ListGroup>
						</ModalBody>
					) : (
						<ModalBody>
							<Loading />
						</ModalBody>
					)}
					<ModalFooter>
						<Button
							onClick={this.save}
							style={{ width: "100%", backgroundColor: "#1e487f" }}
						>
							Save
						</Button>
					</ModalFooter>
				</Modal>
			);
		}
	}
);
