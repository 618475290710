import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import Login from "./Login";
import firebase from "firebase/app";
import SessionIntroduction from "./SessionIntroduction";
import API from "./API";
import Header from "./Header";
import Footer from "./Footer";
import ModeChoice from "./ModeChoice";
import Chat from "./Chat";
import QA from "./QA";
import Dashboard from "./Dashboard/Dashboard";
import Demographics from "./Demographics";
import URLSearchParams from "url-search-params";
// import Loading from "./Loading";
import ReportGeneration from "./ReportGeneration";
import Visibility from "visibilityjs";
import { withRouter } from "react-router";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import Reminders from "./SettingsModals/Reminders";
import DeviceConnections from "./SettingsModals/DeviceConnections";
import globalStore from "./GlobalStore";
import ChangePassword from "./SettingsModals/ChangePassword";
import JaimePersonality from "./SettingsModals/JaimePersonality";
// import { Card } from "@rmwc/card";

// import Voice from "./Voice";
// import VoiceStart from "./VoiceStart";
// import BroadcastChannel from "broadcast-channel";
// const bc = new BroadcastChannel("mhdBroadcastChannel");

const config = {
	apiKey: "AIzaSyCspph1Oyd9tPtbnL2A4MMFgG7Xj0PUV4A",
	authDomain: "mhd-v2.firebaseapp.com",
	databaseURL: "https://mhd-v2.firebaseio.com",
	projectId: "mhd-v2",
	storageBucket: "mhd-v2.appspot.com",
	messagingSenderId: "278077013599"
};
firebase.initializeApp(config);

class App extends Component {
	render() {
		const RoutesWrapped = withRouter(Routes);
		return (
			<FirebaseAuthProvider {...config} firebase={firebase}>
				<BrowserRouter>
					<HttpsRedirect>
						<Header />
						<RoutesWrapped />
						<Footer />
						<SettingsModals />
					</HttpsRedirect>
				</BrowserRouter>
			</FirebaseAuthProvider>
		);
	}
}

class SettingsModals extends Component {
	render() {
		return (
			<div>
				<Reminders />
				<DeviceConnections />
				<ChangePassword />
				<JaimePersonality />
			</div>
		);
	}
}

class Routes extends Component {
	// constructor(props) {
	// 	super(props);
	// }

	componentDidMount() {
		firebase.auth().onAuthStateChanged(user => {
			if (user) {
				if (window.location.pathname.includes("/loginAlexa")) {
					API("/alexaLogin", "GET", {}, data => {
						const params = new URLSearchParams(window.location.search);
						window.location = `${params.get(
							"redirect_uri"
						)}#token_type=Bearer&state=${params.get("state")}&access_token=${
							data.token
						}`;
					});
				} else {
					Visibility.change((e, state) => {
						if (state === "visible") {
							this.setState({ loading: true });
							this.masterRoutingFunction();
						}
					});

					this.masterRoutingFunction();
				}
			} else {
				const pathname = window.location.pathname;
				if (pathname.includes("/loginAlexa")) {
					this.setState({ loading: false });
				} else {
					Visibility.change((e, state) => {});
					this.props.history.push(`/`);

					this.setState({ loading: false });
				}
			}
		});
	}

	masterRoutingFunction = () => {
		API("/routing", "GET", {}, data => {
			globalStore.headerTitle = null;
			globalStore.headerProgress = null;
			if (data.error) {
				this.setState({
					errorMessage: "Authorization error, please try signing in again"
				});
			} else {
				if (data.mode) {
					if (
						data.mode === "questionnaire" &&
						this.props.history.location.pathname !== `/qa/${data.resumeMID}`
					) {
						this.props.history.push(`/qa/${data.resumeMID}`);
					} else if (data.mode === "voice") {
						// if (bowser.mac || bowser.windows) {
						// this.props.history.push(`/voice`);
						// } else {
						API("/switchToChatMode", "GET", {}, () => {
							this.props.history.push(`/chat`);
						});
						// }
					} else if (data.mode === "chat") {
						this.props.history.push(`/chat`);
					} else if (data.mode === "sessionStarted") {
						this.props.history.push(`/sessionIntroduction`);
					}
				} else if (data.lastSessionCompleted) {
					this.props.history.push(`/dashboard`);
				} else {
					this.props.history.push(`/sessionIntroduction`);
				}
				this.setState({ loading: false });
			}
		});
	};

	render() {
		return (
			<div>
				<div className="component masterContainer">
					{/*{this.state.loading ? (*/}
					{/*	<Card*/}
					{/*		style={{*/}
					{/*			marginTop: 25,*/}
					{/*			maxWidth: 500,*/}
					{/*			marginLeft: "auto",*/}
					{/*			marginRight: "auto"*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		<Loading />*/}
					{/*	</Card>*/}
					{/*) : (*/}
					<Switch>
						<Route
							exact
							path="/sessionIntroduction"
							component={SessionIntroduction}
						/>
						<Route exact path="/demographics" component={Demographics} />
						<Route exact path="/modeChoice" component={ModeChoice} />
						{/*<Route exact path="/voiceStart" component={VoiceStart} />*/}
						{/*<Route exact path="/voice" component={Voice} />*/}
						<Route exact path="/chat" component={Chat} />
						<Route exact path="/qa/:mid" component={QA} />
						<Route exact path="/dashboard" component={Dashboard} />
						<Route exact path="/loginAlexa" component={Login} />
						<Route exact path="/generateReport" component={ReportGeneration} />
						<Route exact path="/" component={Login} />
					</Switch>
					{/*)}*/}
				</div>
			</div>
		);
	}
}

export default App;
