import React, { Component } from "react";
import autoBind from "react-autobind";
import { Tailor } from "./Tailor";
import Loading from "./Loading";
import API from "./API";
import { Redirect } from "react-router";
import { Row, Col, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleRight";
import { faComments } from "@fortawesome/free-solid-svg-icons/faComments";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { Card } from "@rmwc/card";
// import bowser from "bowser";
// import { faVolumeUp } from "@fortawesome/free-solid-svg-icons/faVolumeUp";
import { Ripple } from "@rmwc/ripple";

export default class ModeChoice extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = { tailorData: null, redirect: null };
	}

	async componentDidMount() {
		this.setState({ tailorData: await Tailor(9) }, () => {});

		API("/sessionMID", "GET", {}, async data => {
			if (data.mid) {
				const firstQAMIDData = await Tailor(data.mid, {
					returnLinkInfo: true,
					limitToElements: "Next Question",
					currentMode: "questionnaire"
				});

				const linkArray = firstQAMIDData.nextQuestion.match(
					/~\*{}\*~[0-9]+~\*{}\*~/g
				);

				this.setState({
					firstQAMID: linkArray[linkArray.length - 1].replace(/~\*{}\*~/g, "")
				});
			} else {
				const firstQAMIDData = await Tailor(9, {
					returnLinkInfo: true,
					limitToElements: "First Question",
					currentMode: "questionnaire"
				});

				const linkArray = firstQAMIDData.firstQuestion.match(
					/~\*{}\*~[0-9]+~\*{}\*~/g
				);

				this.setState({
					firstQAMID: linkArray[linkArray.length - 1].replace(/~\*{}\*~/g, "")
				});
			}
		});
	}

	tailorData = (key, raw = false) => {
		if (this.state.tailorData) {
			if (raw) {
				return this.state.tailorData[key];
			}
			return (
				<span
					dangerouslySetInnerHTML={{ __html: this.state.tailorData[key] }}
				/>
			);
		} else {
			return <span />;
		}
	};

	qaMode() {
		API("/qAndAQuestionStart", "POST", { mid: this.state.firstQAMID }, () => {
			this.props.history.push(`/qa/${this.state.firstQAMID}`);
		});
	}

	render() {
		return this.state.redirect ? (
			<Redirect to={this.state.redirect} />
		) : !this.state.tailorData || !this.state.firstQAMID ? (
			<Loading />
		) : (
			<Container>
				<Row>
					<Col xs={12} lg={{ size: 8, offset: 2 }}>
						<Card className="modeCard text-center">
							<p className="text-center">{this.tailorData("instructions")}</p>
							{/*{bowser.mac || bowser.windows ? (*/}
							{/*<Container className="modeCardContainer">*/}
							{/*<Row*/}
							{/*className="modeButton justify-content-between"*/}
							{/*onClick={() => this.clickDiv("/voice")}*/}
							{/*>*/}
							{/*<Col xs={1} lg={1}>*/}
							{/*<FontAwesomeIcon*/}
							{/*icon={faVolumeUp}*/}
							{/*color="white"*/}
							{/*size="lg"*/}
							{/*/>*/}
							{/*</Col>*/}
							{/*<Col xs={8} md={10} lg={10}>*/}
							{/*<Row>*/}
							{/*<Col xs={12}>*/}
							{/*<h4>Voice Mode</h4>*/}
							{/*</Col>*/}
							{/*</Row>*/}
							{/*<Row>*/}
							{/*<Col />*/}
							{/*<Col xs={12}>*/}
							{/*<p>Interact with Jaime using device's microphone</p>*/}
							{/*</Col>*/}
							{/*<Col />*/}
							{/*</Row>*/}
							{/*</Col>*/}
							{/*<Col xs={1} lg={1}>*/}
							{/*<FontAwesomeIcon*/}
							{/*icon={faArrowAltCircleRight}*/}
							{/*color="white"*/}
							{/*size="lg"*/}
							{/*/>*/}
							{/*</Col>*/}
							{/*</Row>*/}
							{/*</Container>*/}
							{/*) : null}*/}
							<Ripple>
								<Row
									className="modeButton justify-content-between"
									onClick={() => this.setState({ redirect: "/chat" })}
								>
									<Col
										xs={2}
										style={{
											display: "flex",
											alignItems: "center",
											alignContent: "center",
											flexDirection: "row"
										}}
									>
										<FontAwesomeIcon
											icon={faComments}
											color="white"
											style={{
												fontSize: 30,
												flex: 1
											}}
										/>
									</Col>
									<Col xs={8}>
										<h4 style={{ fontWeight: 700, marginBottom: 0 }}>
											{this.tailorData("chatSubhead")}
										</h4>
										<Row>
											<Col xs={12}>
												<p style={{ margin: 0 }}>
													{this.tailorData("chatBody")}
												</p>
											</Col>
										</Row>
									</Col>
									<Col
										xs={2}
										style={{
											display: "flex",
											alignItems: "center",
											alignContent: "center",
											flexDirection: "row"
										}}
									>
										<FontAwesomeIcon
											icon={faArrowAltCircleRight}
											color="white"
											style={{
												fontSize: 30,
												flex: 1
											}}
										/>
									</Col>
								</Row>
							</Ripple>
							<Ripple>
								<Row
									className="modeButton justify-content-between"
									onClick={this.qaMode}
								>
									<Col
										xs={2}
										style={{
											display: "flex",
											alignItems: "center",
											alignContent: "center",
											flexDirection: "row"
										}}
									>
										<FontAwesomeIcon
											icon={faQuestionCircle}
											color="white"
											style={{ fontSize: 30, flex: 1 }}
										/>
									</Col>
									<Col xs={8}>
										<h4 style={{ fontWeight: 700, marginBottom: 0 }}>
											{this.tailorData("qASubhead")}
										</h4>
										<Row>
											<Col xs={12}>
												<p style={{ margin: 0 }}>{this.tailorData("qABody")}</p>
											</Col>
										</Row>
									</Col>
									<Col
										xs={2}
										style={{
											display: "flex",
											alignItems: "center",
											alignContent: "center",
											flexDirection: "row"
										}}
									>
										<FontAwesomeIcon
											icon={faArrowAltCircleRight}
											color="white"
											style={{ fontSize: 30, flex: 1 }}
										/>
									</Col>
								</Row>
							</Ripple>
						</Card>
					</Col>
				</Row>
				<Row className="dashboardRow lastRow" />
			</Container>
		);
	}
}
