import React, { Component } from "react";
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Col
} from "reactstrap";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import globalStore from "../GlobalStore";
import API from "../API";
import "rc-time-picker/assets/index.css";
import { TextField } from "@rmwc/textfield";
import * as firebase from "firebase/app";

export default observer(
	class ChangePassword extends Component {
		constructor(props) {
			super(props);

			this.state = {
				newPassword: "",
				currentPassword: "",
				errorMessage: null,
				saveButtonText: "Change Password"
			};
		}

		componentDidMount() {}

		closeModal = event => {
			globalStore.changePasswordOpen = false;
		};

		changePassword = () => {
			this.setState({ saveButtonText: "Saving...", errorMessage: null });
			const user = firebase.auth().currentUser;
			const credential = firebase.auth.EmailAuthProvider.credential(
				user.email,
				this.state.currentPassword
			);

			user
				.reauthenticateWithCredential(credential)
				.then(() => {
					user
						.updatePassword(this.state.newPassword)
						.then(() => {
							this.setState({ saveButtonText: "Password Change Successful" });
							setTimeout(() => {
								globalStore.changePasswordOpen = false;
								this.setState({ saveButtonText: "Change Password" });
							}, 2000);
						})
						.catch(error => {
							this.setState({
								errorMessage: error.message,
								saveButtonText: "Change Password"
							});
						});
				})
				.catch(error => {
					this.setState({
						errorMessage: error.message,
						saveButtonText: "Change Password"
					});
				});
		};

		render() {
			return (
				<Modal
					isOpen={globalStore.changePasswordOpen}
					className="reminderSettings"
				>
					<ModalHeader toggle={this.closeModal}>Change Password</ModalHeader>
					<ModalBody>
						{this.state.errorMessage && (
							<p className="errorText text-center">{this.state.errorMessage}</p>
						)}
						<TextField
							outlined
							type="password"
							name="password"
							label="Current Password"
							value={this.state.currentPassword}
							onChange={evt => {
								this.setState({ currentPassword: evt.target.value });
							}}
							required
							autoComplete="current-password"
						/>
						<TextField
							outlined
							type="password"
							name="password"
							label="New Password"
							value={this.state.newPassword}
							onChange={evt => {
								this.setState({ newPassword: evt.target.value });
							}}
							required
							autoComplete="new-password"
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							style={{ width: "100%", backgroundColor: "#1e487f" }}
							onClick={this.changePassword}
							disabled={this.state.saveButtonText !== "Change Password"}
						>
							{this.state.saveButtonText}
						</Button>
					</ModalFooter>
				</Modal>
			);
		}
	}
);
