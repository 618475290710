import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import autoBind from "react-autobind";
import firebase from "firebase/app";
import API from "./API";
import { envInfo } from "./HelperFunctions";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import globalStore from "./GlobalStore";
import {
	Toolbar,
	ToolbarRow,
	ToolbarSection,
	ToolbarTitle,
	ToolbarIcon
} from "@rmwc/toolbar";
import { Menu, MenuItem, MenuSurfaceAnchor } from "@rmwc/menu";
import { ListDivider } from "@rmwc/list";

import { LinearProgress } from "@rmwc/linear-progress";
import { observer } from "mobx-react";

export default observer(
	class Header extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				collapsed: true,
				redirect: null
			};
		}
		toggleNavbar() {
			this.setState({
				collapsed: !this.state.collapsed
			});
		}

		render() {
			const title = globalStore.headerTitle;
			const progress = globalStore.headerProgress;
			if (this.state.redirect) {
				return <Redirect to={this.state.redirect} />;
			}
			return (
				<FirebaseAuthConsumer>
					{({ isSignedIn, user }) => {
						return (
							<Toolbar fixed>
								<ToolbarRow>
									<ToolbarSection alignStart>
										<ToolbarTitle>
											<img
												src="/horizontal-light.svg"
												className="d-inline-block align-top"
												alt="MyHealthyDay logo"
												style={{ maxHeight: 30 }}
											/>
										</ToolbarTitle>
									</ToolbarSection>
									{title || progress ? (
										<ToolbarSection style={{ flexDirection: "column" }}>
											{title ? (
												<label
													style={{
														alignSelf: "center",
														marginBottom: 5,
														fontWeight: 300,
														textAlign: "center"
													}}
												>
													{title}
												</label>
											) : null}
											{progress ? <LinearProgress progress={progress} /> : null}
										</ToolbarSection>
									) : null}
									<ToolbarSection alignEnd>
										{user ? (
											<MenuSurfaceAnchor>
												<Menu
													open={this.state.menuIsOpen}
													onClose={evt => this.setState({ menuIsOpen: false })}
												>
													<MenuItem
														onClick={() => {
															globalStore.jaimePersonalityOpen = true;
														}}
													>
														Jaime Personality
													</MenuItem>
													<MenuItem
														onClick={() => {
															globalStore.remindersOpen = true;
														}}
													>
														Reminder Preferences
													</MenuItem>
													<ListDivider />
													<MenuItem
														onClick={() => {
															globalStore.deviceConnectionsOpen = true;
														}}
													>
														Device Connections
													</MenuItem>
													<ListDivider />
													<MenuItem
														onClick={() => {
															globalStore.changePasswordOpen = true;
														}}
													>
														Change Password
													</MenuItem>
													<MenuItem
														onClick={async () => {
															if (firebase.auth().currentUser) {
																firebase.auth().signOut();
															}
															this.setState({ redirect: "/" });
														}}
													>
														Logout
													</MenuItem>
													{user.email.includes("tuzag") ? (
														<MenuItem
															onClick={async () => {
																API("/reset", "GET", null, data => {
																	window.location = "/";
																});
															}}
														>
															Reset
														</MenuItem>
													) : null}
													<ListDivider />
													<p
														style={{
															textAlign: "right",
															fontSize: 12,
															color: "gray",
															marginBottom: 0,
															marginRight: 10
														}}
													>
														{envInfo()}v. {process.env.REACT_APP_VERSION}
													</p>
												</Menu>
												<ToolbarIcon
													icon="settings"
													onClick={evt =>
														this.setState({
															menuIsOpen: !this.state.menuIsOpen
														})
													}
												/>
											</MenuSurfaceAnchor>
										) : (
											<p
												style={{
													alignSelf: "center",
													marginBottom: 5,
													fontWeight: 300,
													textAlign: "center",
													fontSize: 10
												}}
											>
												{envInfo()}v. {process.env.REACT_APP_VERSION}
											</p>
										)}
									</ToolbarSection>
								</ToolbarRow>
							</Toolbar>
						);
					}}
				</FirebaseAuthConsumer>
			);
		}
	}
);
