import React, { Component } from "react";
import { observer } from "mobx-react";
import { Container } from "reactstrap";
import TellUsMore from "./Rows/TellUsMore";
import JustForYou from "./Rows/JustForYou";
import LearnAndGrow from "./Rows/LearnAndGrow";
import MyProgress from "./Rows/MyProgress";
import CheckInButton from "./Rows/CheckInButton";
import dashboardStore from "./DashboardPageStore";

export default observer(
	class Dashboard extends Component {
		componentWillUnmount() {
			dashboardStore.expandedSection = "tellUsMore";
		}

		render() {
			return (
				<Container>
					<TellUsMore />
					<JustForYou />
					<LearnAndGrow />
					<MyProgress />
					<CheckInButton />
				</Container>
			);
		}
	}
);
