import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "./API";
import { QuestionContainer } from "./Question";
import { Redirect } from "react-router-dom";
import Loading from "./Loading";
import { Row, Col, Container } from "reactstrap";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import { Card } from "@rmwc/card";
import globalStore from "./GlobalStore";

export default class QandA extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			question: null,
			answers: null,
			redirect: null
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.mid !== prevProps.match.params.mid) {
			this.componentDidMount();
		}
	}

	componentDidMount() {
		API(
			"/qAndAQuestion",
			"POST",
			{
				currentMessageID: this.props.match.params.mid
			},
			data => {
				this.setState({
					question: data.question,
					answers: data.answers
				});

				if (data.progress && !isNaN(data.progress)) {
					globalStore.headerProgress = parseInt(data.progress, 10) / 100;
				}
				if (data.title) {
					globalStore.headerTitle = data.title;
				}
			}
		);
	}

	save(pageStore) {
		this.setState({
			question: null,
			answers: null
		});
		API(
			"/qAndAQuestionSave",
			"POST",
			{
				currentMessageID: this.props.match.params.mid,
				answer: pageStore.selectedAnswer,
				otherTextField: pageStore.otherTextField,
				openEndedQuestion: this.state.answers === null
			},
			data => {
				if (data.shouldEndSession) {
					API("/sessionCompleted", "GET", {}, () => {
						globalStore.headerProgress = null;
						globalStore.headerTitle = null;
						this.setState({ redirect: "/dashboard" });
					});
				} else {
					pageStore.selectedAnswer = null;
					pageStore.otherTextField = "";
					pageStore.selectedAnswerDropdown = null;
					this.props.history.push(`/qa/${data.currentMessageID}`);
					this.componentDidMount();
				}
			}
		);
	}

	render() {
		return this.state.redirect ? (
			<Redirect to={this.state.redirect} />
		) : (
			<FirebaseAuthConsumer>
				{({ isSignedIn, user }) => {
					return (
						<Container style={{ minHeight: 200 }}>
							<Row>
								<Col
									xs={{ size: 12 }}
									sm={{ size: 10, offset: 1 }}
									lg={{ size: 6, offset: 3 }}
								>
									<Card>
										{!this.state.question ? (
											<Loading small />
										) : (
											<Container style={{ padding: 25 }}>
												<QuestionContainer
													question={this.state.question}
													answers={this.state.answers}
													save={this.save}
												/>
											</Container>
										)}
									</Card>
								</Col>
							</Row>
							<div className="w-100 d-none d-md-block" />
							{isSignedIn &&
							user.email.includes("tuzag") &&
							!user.email.includes("neal") ? (
								<Row>
									<Col>
										<p style={{ paddingLeft: 15, fontSize: 14, color: "gray" }}>
											Current MID: {this.props.match.params.mid}
										</p>
									</Col>
								</Row>
							) : null}
							<Row className="dashboardRow lastRow" />
						</Container>
					);
				}}
			</FirebaseAuthConsumer>
		);
	}
}
