import React, { Component } from "react";
import { CardHeader, Container } from "reactstrap";
import { QuestionContainer } from "../../Question";
import TooltipItem from "../../Tooltip";
import Loading from "../../Loading";
import questionStore from "../Rows/QuestionStore";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import { Card } from "@rmwc/card";

export default observer(
	class PrivateQuestions extends Component {
		constructor(props) {
			super(props);

			this.state = {
				currentQuestionNum: 1
			};
		}

		setQuestionNumber = () => {
			const privateQuestionNumber = parseInt(
				questionStore.tailorContent.privateQuestionNumber,
				10
			);

			for (let i = 1; i <= privateQuestionNumber; i++) {
				const attribute =
					questionStore.tailorContent[`privateQuestionAttribute_${i}`];

				if (!(attribute in questionStore.answers)) {
					if (i !== this.state.currentQuestionNum) {
						this.setState({ currentQuestionNum: i, saving: false });
					}
					break;
				} else if (i === privateQuestionNumber) {
					this.setState({ privateQAllAnswered: true, saving: false });
				}
			}
		};

		componentWillMount() {
			autorun(() => {
				this.setQuestionNumber();
			});
		}

		save = (pageStore, attribute, answers, privateQuestion) => {
			this.setState({ saving: true });
			this.props.questionSave(pageStore, attribute, answers, privateQuestion);
		};

		privateQuestionSectionGenerator() {
			const attribute =
				questionStore.tailorContent[
					`privateQuestionAttribute_${this.state.currentQuestionNum}`
				];

			if (this.state.privateQAllAnswered) {
				return (
					<Container style={{ padding: 10 }}>
						<p>{questionStore.tailorContent.privateQuestionThankYouText}</p>
					</Container>
				);
			} else {
				try {
					let answers = questionStore.tailorContent[
						`privateQuestionResponses_${this.state.currentQuestionNum}`
					]
						? JSON.parse(
								questionStore.tailorContent[
									`privateQuestionResponses_${this.state.currentQuestionNum}`
								]
						  )
						: null;
					return (
						<Container style={{ padding: 10, minHeight: 150 }}>
							{this.state.saving ? (
								<Loading small />
							) : (
								<QuestionContainer
									question={
										questionStore.tailorContent[
											`privateQuestionQuestion_${this.state.currentQuestionNum}`
										]
									}
									answers={answers}
									save={this.save}
									questionNum={this.state.currentQuestionNum}
									attribute={attribute}
									goBack={this.goBack}
									privateQuestion={true}
								/>
							)}
						</Container>
					);
				} catch (e) {
					console.error(`private question error`);
				}
			}
		}

		render() {
			return (
				<Card
					style={{
						marginTop: 10,
						marginBottom: 10,
						border: 0,
						borderRadius: 10
					}}
				>
					<CardHeader
						style={{
							backgroundColor: "#1E487FCC",
							color: "white",
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10
						}}
					>
						Private Questions
						{questionStore.tailorContent.privateQuestionReinforcement ? (
							<TooltipItem
								id={"PrivateQTooltip"}
								text={questionStore.tailorContent.privateQuestionReinforcement}
							/>
						) : null}
					</CardHeader>
					<div style={{ padding: 5 }}>
						{this.privateQuestionSectionGenerator()}
					</div>
				</Card>
			);
		}
	}
);
