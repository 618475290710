import React, { Component } from "react";
import { CardHeader } from "reactstrap";
import TooltipItem from "../../Tooltip";
import { Card } from "@rmwc/card";

export default class Goals extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	tailorData(key) {
		if (this.props.tailorContent) {
			return (
				<span
					dangerouslySetInnerHTML={{ __html: this.props.tailorContent[key] }}
				/>
			);
		} else {
			return <span />;
		}
	}

	render() {
		let goalsCheckContent = [];

		for (let i = 1; i <= this.props.tailorContent.goalsCheckNumber; i++) {
			goalsCheckContent.push(
				<div
					key={i}
					style={{
						borderBottom:
							i.toString() !== this.props.tailorContent.goalsCheckNumber
								? "1px solid lightGray"
								: null,
						marginTop: 10,
						paddingRight: 10,
						paddingLeft: 10
					}}
				>
					<p style={{ fontWeight: 700 }}>
						{this.tailorData(`goalsCheck_${i}Headline`)}
					</p>
					<p
						style={{ border: "2px #1e487f solid", padding: 5, borderRadius: 5 }}
					>
						{this.tailorData(`goalsCheck_${i}Goal`)}
					</p>
					<p className="goalBody">
						{this.tailorData(`goalsCheck_${i}Benefit`)}
					</p>
				</div>
			);
		}

		return (
			<Card
				style={{
					marginTop: 10,
					marginBottom: 10,
					border: 0,
					borderRadius: 10,
					...this.props.style
				}}
			>
				<CardHeader
					style={{
						backgroundColor: "#1E487FCC",
						color: "white",
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10
					}}
				>
					Goals Check
					{this.props.tailorContent.goalsCheckReinforcement ? (
						<TooltipItem
							id={"goalsTooltip"}
							text={this.props.tailorContent.goalsCheckReinforcement}
						/>
					) : null}
				</CardHeader>
				<div>{goalsCheckContent}</div>
			</Card>
		);
	}
}
