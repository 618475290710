import React, { Component } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import API from "./API";
import camelcase from "camel-case";
import { Card, Col, Container, Row } from "reactstrap";
import { QuestionContainer } from "./Question";
import Loading from "./Loading";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import { Button } from "@rmwc/button";

export default observer(
	class ReportGeneration extends Component {
		constructor(props) {
			super(props);

			this.state = {
				answers: {},
				questionNum: 0,
				tailorContent: null,
				reportData: null
			};
			this.pageStore = new observable({
				selectedAnswer: "",
				otherTextField: "",
				selectedAnswerDropdown: null,
				checkedBoxes: []
			});
		}

		componentDidMount() {
			API(`/tailor/919`, "POST", {}, data => {
				this.setState({ tailorContent: data });
			});
			API(`/reportRemainingQuestions`, "GET", {}, data => {
				this.setState({ remainingQuestions: data.remainingQuestions }, () => {
					this.updateQuestionState();
				});
			});

			API("/reportData", "GET", null, data => {
				this.setState({ reportData: data.reportData ? data.reportData : {} });
			});
		}

		tailorData(key) {
			if (this.state.tailorContent) {
				return (
					<span
						dangerouslySetInnerHTML={{ __html: this.state.tailorContent[key] }}
					/>
				);
			} else {
				return <span />;
			}
		}

		updateQuestionState() {
			if (this.state.remainingQuestions.length === 0) {
				this.setState({ endOfPrivateQuestions: true }, () => {
					API("/generateReportRequest", "GET", {}, () => {});
				});
			} else {
				const currentQuestion = this.state.remainingQuestions[
					this.state.questionNum
				];
				API(
					`/tailor/${currentQuestion.mid}`,
					"POST",
					{
						limitToElements: [currentQuestion.question, currentQuestion.answers]
					},
					tailorContent => {
						this.setState({
							attribute: currentQuestion.key,
							question: tailorContent[camelcase(currentQuestion.question)],
							answers: tailorContent[camelcase(currentQuestion.answers)]
								? JSON.parse(tailorContent[camelcase(currentQuestion.answers)])
								: null
						});
					}
				);
			}
		}

		save = (pageStore, attribute, answers) => {
			if (this.pageStore.checkedBoxes.length > 0) {
				let dataToSend = {};
				for (const value of Object.values(answers[0].checkboxes)) {
					dataToSend[value.value] = this.pageStore.checkedBoxes.includes(
						value.value
					)
						? "checked"
						: "unchecked";
				}

				API(
					"/data",
					"PUT",
					{
						data: dataToSend,
						aggregatable: true
					},
					() => {
						if (
							this.state.questionNum ===
							this.state.remainingQuestions.length - 1
						) {
							this.setState(
								{
									endOfPrivateQuestions: true
								},
								() => {
									API("/generateReportRequest", "GET", {}, () => {});
								}
							);
						} else {
							this.setState(
								{
									questionNum: this.state.questionNum + 1
								},
								() => {
									pageStore.selectedAnswer = null;
									pageStore.otherTextField = null;
									pageStore.selectedAnswerDropdown = null;
									pageStore.checkedBoxes = [];
									this.updateQuestionState();
								}
							);
						}
					}
				);
			} else {
				let answer = pageStore.selectedAnswer
					? pageStore.selectedAnswer
					: pageStore.otherTextField
					? pageStore.otherTextField
					: "";

				API(
					"/data",
					"PUT",
					{
						data: {
							[attribute]: answer
						}
					},
					() => {
						if (
							this.state.questionNum ===
							this.state.remainingQuestions.length - 1
						) {
							this.setState(
								{
									endOfPrivateQuestions: true
								},
								() => {
									API("/generateReportRequest", "GET", {}, () => {});
								}
							);
						} else {
							this.setState(
								{
									questionNum: this.state.questionNum + 1
								},
								() => {
									pageStore.selectedAnswer = null;
									pageStore.otherTextField = null;
									pageStore.selectedAnswerDropdown = null;
									pageStore.checkedBoxes = [];
									this.updateQuestionState();
								}
							);
						}
					}
				);
			}

			this.pageStore.checkedBoxes = [];
		};

		render() {
			return !this.state.tailorContent || this.state.reportData === null ? (
				<Loading />
			) : (
				<FirebaseAuthConsumer>
					{({ isSignedIn, user }) => {
						return (
							<Container>
								<Row className="dashboardRow">
									<Col />
									<Col xs={9}>
										{this.state.endOfPrivateQuestions ? (
											<Card body style={{ marginTop: 50, textAlign: "center" }}>
												{this.state.reportData.token ? (
													<div>
														<p style={{ marginBottom: 0 }}>
															{this.tailorData("reportReadyBody")}
														</p>
														<a
															href={`https://cdn.myhealthyday.com/reports/${
																this.state.reportData.token
															}/MyHealthyReport.pdf`}
															target="_blank"
															rel="noopener noreferrer"
														>
															<Button
																raised
																style={{ marginTop: 15, marginBottom: 15 }}
															>
																{this.tailorData("reportReadyButton")}
															</Button>
														</a>
													</div>
												) : (
													<p style={{ marginBottom: 0 }}>
														{this.tailorData("remainingQuestionThanks")}
													</p>
												)}
											</Card>
										) : (
											<div>
												<Card
													body
													style={{ marginTop: 25, textAlign: "center" }}
												>
													<p style={{ marginBottom: 0 }}>
														{this.tailorData("remainingQuestionInstructions")}
													</p>
												</Card>
												<Card style={{ marginTop: 25 }}>
													<div style={{ padding: 10 }}>
														{!this.state.question ? (
															<Loading />
														) : (
															<Row>
																<Col xs={2} />
																<Col>
																	<QuestionContainer
																		question={this.state.question}
																		answers={this.state.answers}
																		save={this.save}
																		attribute={this.state.attribute}
																	/>
																</Col>
																<Col xs={2} />
															</Row>
														)}
													</div>
												</Card>
											</div>
										)}
									</Col>
									<Col />
								</Row>
								<div className="w-100 d-none d-md-block" />
								{isSignedIn &&
								user.email.includes("tuzag") &&
								!user.email.includes("neal") &&
								this.state.remainingQuestions[this.state.questionNum] ? (
									<Row>
										<Col>
											<p
												style={{
													paddingLeft: 15,
													fontSize: 14,
													color: "gray"
												}}
											>
												Current MID:{" "}
												{
													this.state.remainingQuestions[this.state.questionNum]
														.mid
												}
											</p>
										</Col>
									</Row>
								) : null}
								<Row className="dashboardRow lastRow" />
							</Container>
						);
					}}
				</FirebaseAuthConsumer>
			);
		}
	}
);
