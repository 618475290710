import React, { Component } from "react";
import { CardHeader, Col, Row } from "reactstrap";
import TooltipItem from "../../Tooltip";
import Thumbs from "../../Thumbs";
import { Card } from "@rmwc/card";

export default class Tips extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	tailorData(key) {
		if (this.props.tailorContent) {
			return (
				<span
					dangerouslySetInnerHTML={{ __html: this.props.tailorContent[key] }}
				/>
			);
		} else {
			return <span />;
		}
	}

	render() {
		return (
			<Card
				style={{
					marginTop: 10,
					marginBottom: 10,
					border: 0,
					borderRadius: 10,
					...this.props.style
				}}
			>
				<CardHeader
					style={{
						backgroundColor: "#1E487FCC",
						color: "white",
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10
					}}
				>
					Tips
					<TooltipItem
						id={"tipsTooltip"}
						text={this.props.tailorContent.tipsReinforcement}
					/>
				</CardHeader>
				<div
					style={{ padding: 10, borderBottom: "1px solid lightGray" }}
					className="checkInButton"
				>
					<div>
						<Row style={{ alignItems: "flex-start" }}>
							<Col>{this.tailorData("tips_1")}</Col>
						</Row>
					</div>
					<Thumbs type="tips" number={1} />
				</div>
				<div
					style={{ padding: 10, borderBottom: "1px solid lightGray" }}
					className="checkInButton"
				>
					<div>
						<Row style={{ alignItems: "flex-start" }}>
							<Col>{this.tailorData("tips_2")}</Col>
						</Row>
					</div>
					<Thumbs type="tips" number={2} />
				</div>
				<div style={{ padding: 10 }} className="checkInButton">
					<div>
						<Row style={{ alignItems: "flex-start" }}>
							<Col>{this.tailorData("tips_3")}</Col>
						</Row>
					</div>
					<Thumbs type="tips" number={3} />
				</div>
			</Card>
		);
	}
}
