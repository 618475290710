import React, { Component } from "react";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import { Tailor } from "./Tailor";
import Loading from "./Loading";
import API from "./API";
import { Redirect } from "react-router";
import { Col, Row, Container } from "reactstrap";
import { Card } from "@rmwc/card";
import { Button } from "@rmwc/button";

export default observer(
	class SessionIntroduction extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = { redirect: null };
		}

		async componentDidMount() {
			API("/getData/sessionCount", "POST", {}, async data => {
				if (!data || !data.data || data.data.valueCol === "1") {
					this.setState({ tailorData: await Tailor(21) });
				} else if (data.data.valueCol === "2") {
					this.setState({ tailorData: await Tailor(43) });
				} else if (data.data.valueCol === "3") {
					this.setState({ tailorData: await Tailor(53) });
				} else if (data.data.valueCol === "4") {
					this.setState({ tailorData: await Tailor(73) });
				} else if (data.data.valueCol === "5") {
					this.setState({ tailorData: await Tailor(110) });
				} else if (data.data.valueCol === "6") {
					this.setState({ tailorData: await Tailor(117) });
				} else if (data.data.valueCol === "7") {
					this.setState({ tailorData: await Tailor(125) });
				} else if (data.data.valueCol === "8") {
					this.setState({ tailorData: await Tailor(129) });
				} else if (data.data.valueCol === "9") {
					this.setState({ tailorData: await Tailor(132) });
				} else if (data.data.valueCol === "10") {
					this.setState({ tailorData: await Tailor(137) });
				} else {
					this.setState({ tailorData: await Tailor(142) });
				}
			});
		}

		tailorData = (key, raw = false) => {
			if (this.state.tailorData) {
				if (raw) {
					return this.state.tailorData[key];
				}
				return (
					<span
						dangerouslySetInnerHTML={{ __html: this.state.tailorData[key] }}
					/>
				);
			} else {
				return <span />;
			}
		};

		render() {
			return this.state.redirect ? (
				<Redirect to={this.state.redirect} />
			) : this.state.tailorData ? (
				<Container>
					<Row>
						<Col xs={12} lg={{ size: 8, offset: 2 }}>
							<Card style={{ padding: "7%" }}>
								<h2 className="text-center">{this.tailorData("headline")}</h2>
								<p style={{ marginTop: 15 }}>{this.tailorData("body")}</p>
								<ul>
									<li>{this.tailorData("bullet_1")}</li>
									<li>{this.tailorData("bullet_2")}</li>
									<li>{this.tailorData("bullet_3")}</li>
								</ul>
								<Row>
									<Col xs={{ offset: 3, size: 6 }} style={{ marginTop: 15 }}>
										<Button
											raised
											onClick={() => {
												this.setState({ redirect: "/modeChoice" });
											}}
										>
											{this.tailorData("button")}
										</Button>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
					<Row className="dashboardRow lastRow" />
				</Container>
			) : (
				<Loading />
			);
		}
	}
);
