import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronDown,
	faChevronUp,
	faExternalLinkAlt
} from "@fortawesome/free-solid-svg-icons";
import { Elevation } from "@rmwc/elevation";
import { Ripple } from "@rmwc/ripple";

export default class Offer extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		if (this.props.expandedOffers.includes(this.props.offerIndex)) {
			return (
				<Elevation
					style={{
						marginTop: 10,
						marginBottom: 10,
						borderRadius: 15,
						height: "calc(100% - 20px)",
						display: "flex",
						flexDirection: "column"
					}}
					className="offerCard"
					z={10}
				>
					<div
						className="text-right"
						style={{ marginRight: 20, marginTop: 15 }}
						onClick={() => {
							this.props.offerClicked(this.props.offerIndex);
						}}
					>
						<FontAwesomeIcon icon={faChevronUp} color="#7A7F1C" />
					</div>
					<div
						onClick={() => {
							this.props.offerClicked(this.props.offerIndex);
						}}
						style={{ padding: "5%", flexGrow: 1 }}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								paddingLeft: 5,
								paddingRight: 5,
								marginBottom: 20,
								height: 100
							}}
						>
							<img
								style={{
									maxWidth: "100%",
									maxHeight: "100%",
									objectFit: "contain"
								}}
								src={`https://cdn.myhealthyday.com/offers/${
									this.props.offerData[this.props.offerIndex].id
								}.${this.props.offerData[this.props.offerIndex].fileExt}`}
								alt={this.props.offerData[this.props.offerIndex].name}
							/>
						</div>
						<div style={{ fontSize: 12, paddingTop: 15 }}>
							{this.props.offerData[this.props.offerIndex].body}
						</div>
					</div>

					<Ripple>
						<a
							style={{
								color: "white",
								width: "100%",
								backgroundColor: "#7A7F1C",
								padding: 15,
								alignSelf: "flex-end",
								borderRadius: "0 0 10px 10px"
							}}
							href={this.props.offerData[this.props.offerIndex].link}
							target="_blank"
							rel="noopener noreferrer"
						>
							Visit Site
							<div className="float-right">
								<FontAwesomeIcon icon={faExternalLinkAlt} color="white" />
							</div>
						</a>
					</Ripple>
				</Elevation>
			);
		} else {
			return (
				<Elevation
					style={{
						alignItems: "center",
						marginTop: 10,
						marginBottom: 10,
						borderRadius: 10
					}}
					className="checkInButtonSmall"
					onClick={() => {
						this.props.offerClicked(this.props.offerIndex);
					}}
					z={10}
				>
					<Ripple>
						<div
							className="offerMedia"
							style={{
								alignItems: "middle",
								alignContent: "center",
								flexDirection: "row",
								display: "flex",
								cursor: "pointer"
							}}
						>
							<div
								style={{
									flex: 5,
									height: 60,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									paddingLeft: 5,
									paddingRight: 5
								}}
							>
								<img
									style={{
										paddingTop: 10,
										paddingBottom: 10,
										maxHeight: "100%",
										maxWidth: "100%",
										objectFit: "contain"
									}}
									src={`https://cdn.myhealthyday.com/offers/${
										this.props.offerData[this.props.offerIndex].id
									}.${this.props.offerData[this.props.offerIndex].fileExt}`}
									alt={this.props.offerData[this.props.offerIndex].name}
								/>
							</div>
							<div
								style={{
									flex: 5,
									flexDirection: "column",
									justifyContent: "center",
									display: "flex",
									paddingLeft: 5,
									paddingRight: 5
								}}
							>
								<p
									className="offerButtonText"
									style={{
										fontSize: 12,
										marginBottom: 0,
										textAlign: "center"
									}}
								>
									Click to see your personal offer
								</p>
							</div>
							<div
								style={{
									flex: 1,
									flexDirection: "column",
									justifyContent: "center",
									display: "flex"
								}}
							>
								<FontAwesomeIcon icon={faChevronDown} color="#7A7F1C" />
							</div>
						</div>
					</Ripple>
				</Elevation>
			);
		}
	}
}
