import React, { Component } from "react";
import { Col, Row } from "reactstrap";

export default class QuestionNavigation extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		let totalQuestionNum;
		if (this.props.type === "poll") {
			totalQuestionNum = parseInt(this.props.tailorContent.pollNumber, 10);
		} else {
			totalQuestionNum = parseInt(this.props.tailorContent.qoDNumber, 10);
		}

		return (
			<Row className="justify-content-center">
				{this.props.currentQuestionNum > 1 ? (
					<Col className="text-center">
						<button
							onClick={this.props.goBack}
							style={{ width: "30%" }}
							className="squared-blue"
						>
							Back
						</button>
					</Col>
				) : null}
				{this.props.currentQuestionNum < totalQuestionNum ? (
					<Col className="text-center">
						<button
							onClick={this.props.goForward}
							style={{ width: "30%" }}
							className="squared-blue"
						>
							Next
						</button>
					</Col>
				) : null}
			</Row>
		);
	}
}
