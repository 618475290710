import React, { Component } from "react";
import {
	Button,
	ListGroup,
	ListGroupItem,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Col
} from "reactstrap";
import globalStore from "../GlobalStore";
import { observer } from "mobx-react";
import { Tailor } from "../Tailor";
import Loading from "../Loading";
import Select from "react-select";
import API from "../API";
import camelCase from "camel-case";
import { observe } from "mobx";

export default observer(
	class JaimePersonality extends Component {
		constructor(props) {
			super(props);

			this.state = { tailorData: null, samplePhrase: null };
		}

		async componentDidMount() {
			observe(globalStore, async change => {
				if (
					change.name === "jaimePersonalityOpen" &&
					change.newValue === true
				) {
					const tailorData = await Tailor(3480);
					API(
						`/getData`,
						"POST",
						{
							keys: [
								"jaimePatience",
								"jaimeCuriosity",
								"jaimeEmpathy",
								"jaimeCandor",
								"jaimeCharm",
								"jaimeVocabulary",
								"jaimeHumor",
								"jaimeApproach"
							]
						},
						data => {
							let toSet = {};
							for (const [key, value] of Object.entries(data.data)) {
								const keyMinusJaime = key.replace("jaime", "").toLowerCase();

								try {
									const options = JSON.parse(
										tailorData[`${keyMinusJaime}Options`]
									);

									toSet[keyMinusJaime] = options.find(option => {
										return `${option.value}` === value;
									});
								} catch (e) {
									console.error(`Error on ${keyMinusJaime}`);
								}
							}

							this.setState({
								...toSet,
								tailorData
							});
						}
					);
				}
			});
		}

		closeModal() {
			globalStore.jaimePersonalityOpen = false;
		}

		tailorData = (key, raw = false) => {
			if (this.state.tailorData) {
				if (raw) {
					return this.state.tailorData[key];
				}
				return (
					<span
						dangerouslySetInnerHTML={{ __html: this.state.tailorData[key] }}
					/>
				);
			} else {
				return <span />;
			}
		};

		upperFirstChar(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}

		async selectChange(domain, value) {
			this.setState({ [domain]: value }, async () => {
				if (domain !== "approach") {
					const key = `Sample Phrase-${this.upperFirstChar(domain)}`;
					const toSend = {
						limitToElements: key,
						jaimeApproach: this.state.approach.value
					};

					if (this.state.humor) {
						toSend.jaimeHumor = this.state.humor.value;
					}
					if (this.state.charm) {
						toSend.jaimeCharm = this.state.charm.value;
					}
					if (this.state.candor) {
						toSend.jaimeCandor = this.state.candor.value;
					}
					if (this.state.patience) {
						toSend.jaimePatience = this.state.patience.value;
					}
					if (this.state.empathy) {
						toSend.jaimeEmpathy = this.state.empathy.value;
					}
					if (this.state.curiosity) {
						toSend.jaimeCuriosity = this.state.curiosity.value;
					}
					if (this.state.vocabulary) {
						toSend.jaimeVocabulary = this.state.vocabulary.value;
					}

					const tailorData = await Tailor(3480, toSend);

					this.setState({ samplePhrase: tailorData[camelCase(key)] });
				}
			});
		}

		save = () => {
			const toSend = {
				jaimeApproach: this.state.approach.value
			};

			if (this.state.humor) {
				toSend.jaimeHumor = this.state.humor.value;
			}
			if (this.state.charm) {
				toSend.jaimeCharm = this.state.charm.value;
			}
			if (this.state.candor) {
				toSend.jaimeCandor = this.state.candor.value;
			}
			if (this.state.patience) {
				toSend.jaimePatience = this.state.patience.value;
			}
			if (this.state.empathy) {
				toSend.jaimeEmpathy = this.state.empathy.value;
			}
			if (this.state.curiosity) {
				toSend.jaimeCuriosity = this.state.curiosity.value;
			}
			if (this.state.vocabulary) {
				toSend.jaimeVocabulary = this.state.vocabulary.value;
			}

			API(`/data`, "PUT", { data: toSend }, () => {
				globalStore.jaimePersonalityOpen = false;
			});
		};

		render() {
			return (
				<Modal
					isOpen={globalStore.jaimePersonalityOpen}
					toggle={this.closeModal}
					className="jaimePersonalityModal"
				>
					{this.state.tailorData ? (
						[
							<ModalHeader toggle={this.closeModal} key="header">
								{this.tailorData("title")}
							</ModalHeader>,
							<ModalBody key="body" className="settings">
								<p>{this.tailorData("instructions")}</p>
								<ListGroup style={{ marginBottom: 15 }}>
									<ListGroupItem>
										<Row>
											<Col>{this.tailorData("approachSubhead")}</Col>
											<Col>
												<Select
													options={JSON.parse(
														this.state.tailorData.approachOptions
													)}
													onChange={this.selectChange.bind(this, "approach")}
													value={this.state.approach}
												/>
											</Col>
										</Row>
									</ListGroupItem>
								</ListGroup>
								{this.state.samplePhrase ? (
									<ListGroup style={{ marginBottom: 15 }}>
										<ListGroupItem>
											<span>{this.state.samplePhrase}</span>
										</ListGroupItem>
									</ListGroup>
								) : null}
								<ListGroup>
									<ListGroupItem>
										<Row>
											<Col>{this.tailorData("humorSubhead")}</Col>
											<Col>
												<Select
													options={JSON.parse(
														this.state.tailorData.humorOptions
													)}
													onChange={this.selectChange.bind(this, "humor")}
													value={this.state.humor}
												/>
											</Col>
										</Row>
									</ListGroupItem>
									<ListGroupItem>
										<Row>
											<Col>{this.tailorData("charmSubhead")}</Col>
											<Col>
												<Select
													options={JSON.parse(
														this.state.tailorData.charmOptions
													)}
													onChange={this.selectChange.bind(this, "charm")}
													value={this.state.charm}
												/>
											</Col>
										</Row>
									</ListGroupItem>
									<ListGroupItem>
										<Row>
											<Col>{this.tailorData("candorSubhead")}</Col>
											<Col>
												<Select
													options={JSON.parse(
														this.state.tailorData.candorOptions
													)}
													onChange={this.selectChange.bind(this, "candor")}
													value={this.state.candor}
												/>
											</Col>
										</Row>
									</ListGroupItem>
									<ListGroupItem>
										<Row>
											<Col>{this.tailorData("patienceSubhead")}</Col>
											<Col>
												<Select
													options={JSON.parse(
														this.state.tailorData.patienceOptions
													)}
													onChange={this.selectChange.bind(this, "patience")}
													value={this.state.patience}
												/>
											</Col>
										</Row>
									</ListGroupItem>
									<ListGroupItem>
										<Row>
											<Col>{this.tailorData("empathySubhead")}</Col>
											<Col>
												<Select
													options={JSON.parse(
														this.state.tailorData.empathyOptions
													)}
													onChange={this.selectChange.bind(this, "empathy")}
													value={this.state.empathy}
												/>
											</Col>
										</Row>
									</ListGroupItem>
									<ListGroupItem>
										<Row>
											<Col>{this.tailorData("curiositySubhead")}</Col>
											<Col>
												<Select
													options={JSON.parse(
														this.state.tailorData.curiosityOptions
													)}
													onChange={this.selectChange.bind(this, "curiosity")}
													value={this.state.curiosity}
												/>
											</Col>
										</Row>
									</ListGroupItem>
									<ListGroupItem>
										<Row>
											<Col>{this.tailorData("vocabularySubhead")}</Col>
											<Col>
												<Select
													options={JSON.parse(
														this.state.tailorData.vocabularyOptions
													)}
													onChange={this.selectChange.bind(this, "vocabulary")}
													value={this.state.vocabulary}
												/>
											</Col>
										</Row>
									</ListGroupItem>
								</ListGroup>
							</ModalBody>
						]
					) : (
						<ModalBody>
							<Loading />
						</ModalBody>
					)}
					<ModalFooter>
						<Button
							onClick={this.save}
							style={{ width: "100%", backgroundColor: "#1e487f" }}
						>
							Save
						</Button>
					</ModalFooter>
				</Modal>
			);
		}
	}
);
