import React, { Component } from "react";
import { CardHeader, Container } from "reactstrap";
import { QuestionResults } from "../../QuestionResults";
import { QuestionContainer } from "../../Question";
import TooltipItem from "../../Tooltip";
import Loading from "../../Loading";
import questionStore from "../Rows/QuestionStore";
import { observer } from "mobx-react";
import { Card } from "@rmwc/card";

export default observer(
	class QuestionOfTheDay extends Component {
		constructor(props) {
			super(props);

			this.state = { currentQuestionNum: 1,};
		}

		setQuestionNumber = () => {
			const qoDNumber = parseInt(questionStore.tailorContent.qoDNumber, 10);

			for (let i = 1; i <= qoDNumber; i++) {
				const attribute = questionStore.tailorContent[`qoDAttribute_${i}`];

				if (!(attribute in questionStore.answers)) {
					if (i !== this.state.currentQuestionNum) {
						this.setState({ currentQuestionNum: i, saving: false });
					}
					break;
				} else if (i === qoDNumber) {
					this.setState({ saving: false });
				}
			}
		};

		goForward = () => {
			this.setState({
				currentQuestionNum: this.state.currentQuestionNum + 1,
				saving: false
			});
		};

		goBack = () => {
			this.setState({
				currentQuestionNum: this.state.currentQuestionNum - 1,
				saving: false
			});
		};

		componentWillMount() {
			this.setQuestionNumber();
		}

		questionSectionGenerator() {
			let content = [];

			if (questionStore.tailorContent) {
				const attribute =
					questionStore.tailorContent[
						`qoDAttribute_${this.state.currentQuestionNum}`
					];
				const question =
					questionStore.tailorContent[
						`qoDQuestion_${this.state.currentQuestionNum}`
					];
				const answers = questionStore.tailorContent[
					`qoDResponses_${this.state.currentQuestionNum}`
				]
					? JSON.parse(
							questionStore.tailorContent[
								`qoDResponses_${this.state.currentQuestionNum}`
							]
					  )
					: null;

				if (attribute in questionStore.answers) {
					return (
						<Container style={{ padding: 10 }}>
							<QuestionResults
								tailorContent={questionStore.tailorContent}
								answer={questionStore.answers[attribute]}
								attribute={attribute}
								answers={answers}
								question={question}
								type="qoD"
								questionNum={this.state.currentQuestionNum}
								goForward={this.goForward}
								goBack={this.goBack}
							/>
						</Container>
					);
				} else {
					return (
						<Container style={{ padding: 10 }}>
							{this.state.saving ? (
								<Loading small />
							) : (
								<QuestionContainer
									questionNum={this.state.currentQuestionNum}
									question={question}
									answers={answers}
									attribute={attribute}
									save={this.save}
									type="qoD"
									goBack={this.goBack}
								/>
							)}
						</Container>
					);
				}
			}
			return content;
		}

		save = (pageStore, attribute, answers, privateQuestion) => {
			this.setState({ saving: true });
			this.props.questionSave(pageStore, attribute, answers, privateQuestion);
		};

		render() {
			let qod = this.questionSectionGenerator("QoD");

			return (
				<Card
					style={{
						marginTop: 10,
						marginBottom: 10,
						border: 0,
						borderRadius: 10,
						...this.props.style
					}}
				>
					<CardHeader
						style={{
							backgroundColor: "#1E487FCC",
							color: "white",
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10
						}}
					>
						Question of the Day
						<TooltipItem
							id={"qoDTooltip"}
							text={questionStore.tailorContent.qoDReinforcement}
						/>
					</CardHeader>

					<div style={{ padding: 5, minHeight: 150  }}>
						{qod}
						{questionStore.tailorContent.qoDNumber !== "1" ? (
							<div
								className="text-right"
								style={{
									color: "gray",
									fontSize: 12,
									paddingRight: 15
								}}
							>
								{this.state.currentQuestionNum}/
								{questionStore.tailorContent.qoDNumber}
							</div>
						) : null}
					</div>
				</Card>
			);
		}
	}
);
