import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col, Container } from "reactstrap";
import { TextField } from "@rmwc/textfield";
import { Elevation } from "@rmwc/elevation";
import API from "./API";
import shortid from "shortid";
import { Redirect } from "react-router";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import { Card } from "@rmwc/card";
import globalStore from "./GlobalStore";
import { Button } from "@rmwc/button";

export function randInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

export default class Chat extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			input: "",
			messages: [
				{
					content: (
						<FontAwesomeIcon
							icon={faSpinner}
							spin
							color="white"
							aria-hidden="true"
						/>
					),
					jaime: true,
					id: shortid.generate()
				}
			],
			currentMessageID: "10",
			endOfCheckin: false,
			approach: "MHD"
		};
	}

	componentDidMount() {
		API("/chat", "GET", {}, data => {
			if (data.jaimeApproach && data.jaimeApproach.valueCol) {
				this.setState({ approach: data.jaimeApproach.valueCol });
			}

			if (data.progress && !isNaN(data.progress)) {
				globalStore.headerProgress = parseInt(data.progress, 10) / 100;
			}
			if (data.title) {
				globalStore.headerTitle = data.title;
			}

			this.setState({ messages: data.messages }, () => {
				if (data.messages.length === 0) {
					API(
						"/chat",
						"POST",
						{
							text: "start",
							currentMessageID: this.state.currentMessageID
						},
						data => {
							this.setState({
								currentMessageID:
									data.responseMessages[data.responseMessages.length - 1]
										.messageID,
								messages: data.responseMessages
							});

							if (data.progress && !isNaN(data.progress)) {
								globalStore.headerProgress = parseInt(data.progress, 10) / 100;
							}
							if (data.title) {
								globalStore.headerTitle = data.title;
							}
						}
					);
				} else {
					this.setState({
						currentMessageID: data.messages[data.messages.length - 1].messageID
					});
					setTimeout(() => {
						const bottomOfMessages = document.querySelector(
							`#bottomOfMessages`
						);
						if (bottomOfMessages) {
							bottomOfMessages.scrollIntoView({ behavior: "smooth" });
						}
					}, 100);
				}
			});
		});
	}

	sendMessage(event) {
		event.preventDefault();

		if (this.state.input) {
			const sendTime = new Date();
			this.setState(
				{
					input: "",
					messages: [
						...this.state.messages,
						{
							content: this.state.input,
							jaime: false,
							id: shortid.generate()
						},
						{
							content: (
								<FontAwesomeIcon
									icon={faSpinner}
									spin
									color="white"
									aria-hidden="true"
								/>
							),
							jaime: true,
							id: shortid.generate()
						}
					]
				},
				() => {
					document
						.querySelector(`#bottomOfMessages`)
						.scrollIntoView({ behavior: "smooth" });
				}
			);
			API(
				"/chat",
				"POST",
				{
					currentMessageID: this.state.currentMessageID,
					text: this.state.input
				},
				data => {
					/* OK, I know the setTimeout duration calculation is crazy at the end
					of this function but it's basically generate a waiting time between 1
					and 2.5 seconds and then subtract the time it took to get the response,
					if the waiting time was already used in the load, make the timeout
					number 0 so it auto runs, otherwise subtract the loading time from
					the waiting time and wait that long to show the response */
					this.setState({
						currentMessageID:
							data.responseMessages[data.responseMessages.length - 1].messageID
					});
					let updatedMessages = this.state.messages;

					//remove all loading messages (so she can't be typing two messages at the same time)
					updatedMessages = updatedMessages.filter(message => {
						return typeof message.content === "string";
					});

					updatedMessages = [...updatedMessages, data.responseMessages[0]];
					if (data.responseMessages.length === 2) {
						updatedMessages.push({
							content: (
								<FontAwesomeIcon
									icon={faSpinner}
									spin
									color="white"
									aria-hidden="true"
								/>
							),
							jaime: true,
							id: shortid.generate()
						});
					}

					const firstMessageWaitTime = Math.max(
						data.responseMessages[0].content.length * randInt(10, 14) -
							Math.abs(new Date() - sendTime),
						0
					);

					const secondMessageWaitTime =
						data.responseMessages.length === 2
							? data.responseMessages[1].content.length * randInt(10, 14) +
							  firstMessageWaitTime
							: firstMessageWaitTime;
					if (data.endOfCheckin) {
						this.setState({ endOfCheckin: true });
						API("/sessionCompleted", "GET", {}, () => {});
					}

					setTimeout(() => {
						this.setState(
							{
								messages: updatedMessages
							},
							() => {
								document
									.querySelector(`#bottomOfMessages`)
									.scrollIntoView({ behavior: "smooth" });
								if (data.responseMessages.length === 2) {
									setTimeout(() => {
										let updatedMessages = [...this.state.messages];

										//remove all loading messages (so she can't be typing two messages at the same time)
										updatedMessages = updatedMessages.filter(message => {
											return typeof message.content === "string";
										});

										updatedMessages = [
											...updatedMessages,
											data.responseMessages[1]
										];

										this.setState(
											{
												messages: updatedMessages
											},
											() => {
												document
													.querySelector(`#bottomOfMessages`)
													.scrollIntoView({ behavior: "smooth" });
											}
										);

										if (data.progress && !isNaN(data.progress)) {
											globalStore.headerProgress =
												parseInt(data.progress, 10) / 100;
										}
										if (data.title) {
											globalStore.headerTitle = data.title;
										}
									}, secondMessageWaitTime);
								} else {
									if (data.progress && !isNaN(data.progress)) {
										globalStore.headerProgress =
											parseInt(data.progress, 10) / 100;
									}
									if (data.title) {
										globalStore.headerTitle = data.title;
									}
								}
							}
						);
					}, firstMessageWaitTime);
				}
			);
		}
	}

	textboxKeyPress(event) {
		if (event.key === "Enter") {
			event.preventDefault();
			this.sendMessage(event);
		}
	}
	render() {
		return this.state.redirect ? (
			<Redirect to={this.state.redirect} />
		) : (
			<FirebaseAuthConsumer>
				{({ isSignedIn, user }) => {
					return (
						<Container className="chatContainer">
							<Card>
								<div id="messagesContainer">
									{this.state.messages.map((message, index) => {
										return message.jaime ? (
											<JaimeMessage
												key={`${message.id}|${index}`}
												content={message.content}
												approach={this.state.approach}
											/>
										) : (
											<UserMessage key={message.id} content={message.content} />
										);
									})}
									<div id="bottomOfMessages" />
								</div>
								{this.state.endOfCheckin ? (
									<Row
										style={{
											paddingBottom: 5,
											marginTop: 15,
											marginBottom: 15
										}}
									>
										<Col className="text-center">
											<Button
												onClick={() => {
													globalStore.headerTitle = null;
													globalStore.headerProgress = null;
													this.setState({ redirect: "/dashboard" });
												}}
												style={{
													width: "30%"
												}}
												raised
											>
												Go To Dashboard
											</Button>
										</Col>
									</Row>
								) : (
									<div className=" textboxRow" style={{ display: "flex" }}>
										<TextField
											label="Text message"
											value={this.state.input}
											onChange={evt => {
												this.setState({ input: evt.target.value });
											}}
											onKeyPress={this.textboxKeyPress}
											// style={{ flex: 1 }}
										/>
										<button
											style={{
												background: "white",
												color: "#1E487F",
												border: 0,
												fontWeight: 600
											}}
											onClick={this.sendMessage}
										>
											Send
										</button>
									</div>
								)}

								{/*{isSignedIn &&*/}
								{/*user.email.includes("tuzag") &&*/}
								{/*!user.email.includes("neal") ? (*/}
								{/*<Row>*/}
								{/*<Col>*/}
								{/*<p*/}
								{/*style={{*/}
								{/*paddingLeft: 15,*/}
								{/*fontSize: 14,*/}
								{/*color: "gray"*/}
								{/*}}*/}
								{/*>*/}
								{/*Current MID: {this.state.currentMessageID}*/}
								{/*</p>*/}
								{/*</Col>*/}
								{/*</Row>*/}
								{/*) : null}*/}
							</Card>
						</Container>
					);
				}}
			</FirebaseAuthConsumer>
		);
	}
}

const UserMessage = ({ content }) => {
	return (
		<Row
			style={{
				margin: 0,
				paddingLeft: 15,
				paddingRight: 15,
				paddingTop: 10,
				paddingBottom: 10,
				flexDirection: "row-reverse"
			}}
		>
			<div
				style={{
					padding: 0,
					display: "flex",
					alignSelf: "flex-end",
					marginLeft: 10
				}}
			>
				<Elevation
					z={3}
					style={{
						borderRadius: 150,
						background: "white",
						width: 45,
						height: 45,
						alignSelf: "flex-end",
						marginBottom: 5,
						border: "1px solid #1E487F"
					}}
				>
					<img
						alt="user profile icon in chat bubble"
						src="/user.svg"
						style={{
							width: 45,
							height: 45,
							padding: 10
						}}
					/>
				</Elevation>
			</div>
			<Elevation
				style={{
					background: "#1E487F",
					borderRadius: 5,
					paddingTop: 12,
					paddingBottom: 12,
					paddingLeft: 10,
					paddingRight: 10,
					fontFamily: "Open Sans",
					fontSize: 16,
					color: "white",
					maxWidth: "80%"
				}}
				z={3}
				className="text-right"
			>
				{content}
			</Elevation>
		</Row>
	);
};

const JaimeMessage = ({ content, approach }) => {
	let icon = "/MHDLogoSquare.png";
	let messageBubbleColor = "#D8D8D8";

	if (approach === "bff") {
		messageBubbleColor = "#D1383F";
		icon = "/bff.png";
	} else if (approach === "coach") {
		messageBubbleColor = "#16B1F0";
		icon = "/coach.png";
	} else if (approach === "drillSergeant") {
		messageBubbleColor = "#390A4E";
		icon = "/drill.png";
	} else if (approach === "mentor") {
		messageBubbleColor = "#EF5F2F";
		icon = "/mentor.png";
	} else if (approach === "mom") {
		messageBubbleColor = "#F2A524";
		icon = "/mom.png";
	}

	let iconStyle = {
		width: 30,
		marginTop: 4
	};

	return (
		<Row
			style={{
				margin: 0,
				paddingLeft: 15,
				paddingRight: 15,
				paddingTop: 10,
				paddingBottom: 10
			}}
		>
			<div
				style={{
					padding: 0,
					display: "flex",
					alignSelf: "flex-end",
					marginRight: 10
				}}
			>
				<Elevation
					z={3}
					style={{
						borderRadius: 150,
						background: "white",
						width: 40,
						height: 40,
						alignSelf: "flex-center",
						marginHeight: 5,
						border: `1px solid ${messageBubbleColor}`
					}}
					className="text-center"
				>
					<img alt="tuzag logo in chat bubble" src={icon} style={iconStyle} />
				</Elevation>
			</div>
			<Elevation
				style={{
					background: "#D8D8D8",
					borderRadius: 5,
					padding: 10,
					fontFamily: "Open Sans",
					fontSize: 16,
					color: "#3f4041",
					maxWidth: "80%"
				}}
				z={3}
			>
				{typeof content === "string" ? (
					<span dangerouslySetInnerHTML={{ __html: content }} />
				) : (
					content
				)}
			</Elevation>
		</Row>
	);
};
