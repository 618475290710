import React from "react";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Row,
	Col,
	Container,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTwitter,
	faMediumM,
	faLinkedinIn,
	faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import Intercom from "react-intercom";
import firebase from "firebase";
import moment from "moment";

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			backdrop: true,
			intercom: false,
			email: null,
			name: null,
		};

		this.toggle = this.toggle.bind(this);
		this.changeBackdrop = this.changeBackdrop.bind(this);
	}

	componentDidMount() {
		let urlParams = window.location.href;
		if (urlParams.includes("privacy")) {
			this.setState({
				modal: true,
			});
		}

		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({ email: user.email, name: user.displayName });
				// this.setState({user})
			}
		});
	}

	toggle() {
		this.setState({
			modal: !this.state.modal,
		});
	}

	changeBackdrop(e) {
		let value = e.target.value;
		if (value !== "static") {
			value = JSON.parse(value);
		}
		this.setState({ backdrop: value });
	}

	render() {
		const exampleAttributes = {
			custom_launcher_selector: "#intercom",
			hide_default_launcher: true,
		};

		return (
			<footer
				className="fixed-bottom"
				style={{ background: "rgba(63,64,65,.9)", padding: 0 }}
			>
				<div className="d-lg-none d-xl-none" style={{ marginTop: 10 }} />
				<Container>
					<Row>
						<Col xs={12} md={6} lg={4}>
							<p style={{ marginBottom: 0 }}>
								© {moment().format("Y")},{" "}
								<a
									href="https://wearetuzag.com"
									target="_blank"
									className="tuzag"
									rel="noopener noreferrer"
								>
									<strong>
										<i>tuzag</i>
									</strong>
								</a>
								, Inc. All rights reserved.
							</p>
						</Col>
						<Col md={12} lg={4} className="d-none d-lg-block">
							<ul className="list-social list-inline">
								<li className="list-inline-item">
									<a
										href="https://twitter.com/@mhdsocialmedia"
										target="_blank"
										rel="noopener noreferrer"
									>
										<FontAwesomeIcon icon={faTwitter} size="lg" />
									</a>
								</li>
								<li className="list-inline-item">
									<a
										href="https://medium.com/@MHDSocialMedia"
										target="_blank"
										rel="noopener noreferrer"
									>
										<FontAwesomeIcon icon={faMediumM} size="lg" />
									</a>
								</li>
								<li className="list-inline-item">
									<a
										href="https://www.facebook.com/mhdSocial/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<FontAwesomeIcon icon={faFacebookF} size="lg" />
									</a>
								</li>
								<li className="list-inline-item">
									<a
										href="https://www.linkedin.com/company/2948957"
										target="_blank"
										rel="noopener noreferrer"
									>
										<FontAwesomeIcon icon={faLinkedinIn} size="lg" />
									</a>
								</li>
							</ul>
						</Col>
						<Col xs={12} md={6} lg={4} id="footer">
							<p style={{ marginBottom: 0 }}>
								<a href="#footer" onClick={this.toggle}>
									Privacy Policy
								</a>{" "}
								|{" "}
								<a href="#footer" id="intercom">
									<Intercom
										appID="kk1fosp7"
										{...exampleAttributes}
										name={this.state.name}
										email={this.state.email}
									/>
									Support
								</a>
							</p>
						</Col>
					</Row>
					<div className="d-lg-none d-xl-none" style={{ marginTop: 10 }} />
				</Container>

				<Modal
					isOpen={this.state.modal}
					toggle={this.toggle}
					className={this.props.className}
					backdrop={this.state.backdrop}
					id="privacyPolicy"
				>
					<ModalHeader toggle={this.toggle}>Privacy</ModalHeader>
					<ModalBody>
						MyHealthyDay is a product of tuzag, Inc. MyHealthyDay, as
						personified by Jaime, is going to ask you a lot of questions. Some
						questions, like your favorite movie or your favorite kind of music,
						will only be asked once, during the first ten sessions, when you are
						teaching Jaime how best to work with you. Jaime asks this kind of
						question to learn how to give you a better, more personalized
						experience here that aligns with your tastes and preferences. Some
						questions, like how you slept, or what your energy level and mood
						are like, will be asked up to once per day, depending on how often
						you interact with Jaime. You do not have to answer any of these
						questions! But we hope that you will, because your answers help
						Jaime match you up with tools and resources that we think will work
						best for you. MyHealthyDay uses the information you share with Jaime
						to personalize your experience here. We use both information you
						have directly provided by answering questions, and information about
						the technology you are using to interact with us, to make your
						experience better. We never share your personal or technical
						information with anyone else, human or corporate, in a way that
						could be used to identify you personally. As long as there are at
						least 100 users in the community, we do share information about the
						user community in aggregate with the sponsor who pays for your
						access here. This kind of information may include observations like
						“20% of your user population would like help managing stress,” or
						“35% are interested in losing weight.” It may also include
						information about which programs and resources are popular in the
						user community. We keep your information for as long as your account
						is active here. You may delete your account at any time. (Directions
						for doing that here.) You may get in touch with our Privacy
						Compliance Officer by emailing{" "}
						<a href="mailto:support@myhealhyday.com">
							support@myhealthyday.com
						</a>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={this.toggle}>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			</footer>
		);
	}
}
export default Footer;
