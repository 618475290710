import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import API from "../../API";
import Offer from "../Cards/Offer";
import dashboardStore from "../DashboardPageStore";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import { Card } from "@rmwc/card";
import { Ripple } from "@rmwc/ripple";

export default observer(
	class JustForYou extends Component {
		constructor(props) {
			super(props);

			this.state = { offerData: null, expandedOffers: [] };
		}

		componentDidMount() {
			API("/offers/3", "GET", {}, data => {
				this.setState({
					offerData: data.offers
				});
			});

			autorun(() => {
				if (dashboardStore.expandedSection !== "offers") {
					this.setState({ expandedOffers: [] });
				}
			});
		}

		offerClicked = offerIndex => {
			if (this.state.expandedOffers.includes(offerIndex)) {
				this.setState({
					expandedOffers: this.state.expandedOffers.filter(index => {
						return index !== offerIndex;
					})
				});
			} else {
				this.setState(
					{
						expandedOffers: [...this.state.expandedOffers, offerIndex]
					},
					() => {
						window.scrollTo({
							left: 0,
							top: this.container.offsetTop - 65,
							behavior: "smooth"
						});
					}
				);
			}
		};

		render() {
			return (
				<Card
					style={{
						border: 0,
						marginTop: 10,
						marginBottom: 10,
						borderRadius: 10
					}}
					className="checkInButton"
				>
					<Ripple
						onClick={() => {
							if (this.state.expandedOffers.length === 3) {
								this.setState({ expandedOffers: [] });
								dashboardStore.expandedSection = null;
							} else {
								this.setState({ expandedOffers: [0, 1, 2] });
								dashboardStore.expandedSection = "offers";
							}
						}}
					>
						<div
							ref={ref => {
								this.container = ref;
							}}
							style={{
								paddingTop: 12,
								paddingBottom: 12,
								paddingRight: 20,
								paddingLeft: 20,
								backgroundColor: "#7A7F1C",
								cursor: "pointer",
								color: "white",
								borderRadius: 10,
								borderBottomLeftRadius: 0,
								borderBottomRightRadius: 0
							}}
						>
							Just for you...
							{this.state.expandedOffers.length === 3 ? (
								<div className="float-right">
									<FontAwesomeIcon icon={faChevronUp} color="white" />
								</div>
							) : (
								<div className="float-right">
									<FontAwesomeIcon icon={faChevronDown} color="white" />
								</div>
							)}
						</div>
					</Ripple>
					{this.state.offerData ? (
						<Row
							style={{
								paddingLeft: 20,
								paddingRight: 20
							}}
						>
							<Col md={12} lg={4}>
								<Offer
									offerIndex={0}
									offerClicked={this.offerClicked}
									expandedOffers={this.state.expandedOffers}
									offerData={this.state.offerData}
								/>
							</Col>
							<Col md={12} lg={4}>
								<Offer
									offerIndex={1}
									offerClicked={this.offerClicked}
									expandedOffers={this.state.expandedOffers}
									offerData={this.state.offerData}
								/>
							</Col>
							{this.state.offerData.length > 2 ? (
								<Col md={12} lg={4}>
									<Offer
										offerIndex={2}
										offerClicked={this.offerClicked}
										expandedOffers={this.state.expandedOffers}
										offerData={this.state.offerData}
									/>
								</Col>
							) : null}
						</Row>
					) : null}
				</Card>
			);
		}
	}
);
