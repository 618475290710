import React from "react";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";

class TooltipItem extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			tooltipOpen: false
		};
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		});
	}

	render() {
		return (
			<span>
				<div className="float-right">
					<FontAwesomeIcon
						icon={faInfoCircle}
						color="white"
						id={"Tooltip-" + this.props.id}
					/>
				</div>

				<Tooltip
					placement="right"
					isOpen={this.state.tooltipOpen}
					target={"Tooltip-" + this.props.id}
					toggle={this.toggle}
					style={{ backgroundColor: "#1E487F" }}
				>
					{this.props.text}
				</Tooltip>
			</span>
		);
	}
}

export default TooltipItem;
