import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons/faThumbsDown";
import API from "./API";

class Thumbs extends React.Component {
	constructor(props) {
		super(props);

		this.state = { thumbsUp: false, thumbsDown: false };
	}

	componentDidMount() {
		API(`/useful/${this.props.type}/${this.props.number}`, "GET", {}, data => {
			if (data.thumbsUp) {
				this.setState({ thumbsUp: true });
			} else if (data.thumbsUp === 0) {
				this.setState({ thumbsDown: true });
			}
		});
	}

	onPressThumbUp = () => {
		this.setState({ thumbsUp: true, thumbsDown: false });
		API(`/useful`, "PUT", {
			contentNumber: this.props.number,
			contentType: this.props.type,
			thumbsUp: true
		});
	};

	onPressThumbDown = () => {
		this.setState({ thumbsDown: true, thumbsUp: false });
		API(`/useful`, "PUT", {
			contentNumber: this.props.number,
			contentType: this.props.type,
			thumbsUp: false
		});
	};

	render() {
		return (
			<div
				className="text-center"
				style={{
					backgroundColor: "white",
					border: "none",
					marginTop: 10,
					justifyContent: "space-between"
				}}
			>
				<FontAwesomeIcon
					className={`text-center ${this.state.thumbsUp ? "" : "thumbHover"}`}
					icon={faThumbsUp}
					color={this.state.thumbsUp ? "#FFB100" : "#1E487F"}
					style={{ marginLeft: 10, marginRight: 10, cursor: "pointer" }}
					onClick={this.onPressThumbUp}
				/>
				<p style={{ marginBottom: 0, display: "inline" }}>Useful?</p>
				<FontAwesomeIcon
					className={`text-center ${this.state.thumbsUp ? "" : "thumbHover"}`}
					icon={faThumbsDown}
					color={this.state.thumbsDown ? "#FFB100" : "#1E487F"}
					style={{ marginLeft: 10, marginRight: 10, cursor: "pointer" }}
					onClick={this.onPressThumbDown}
				/>
			</div>
		);
	}
}

export default Thumbs;
