import React, { Component } from "react";
import { CardHeader } from "reactstrap";
import TooltipItem from "../../Tooltip";
import { Card } from "@rmwc/card";

export default class DailyRundown extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<Card
				style={{
					marginTop: 10,
					marginBottom: 10,
					border: 0,
					borderRadius: 10,
					...this.props.style
				}}
			>
				<CardHeader
					style={{
						backgroundColor: "#1E487FCC",
						color: "white",
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10
					}}
				>
					Daily Rundown
					{this.props.tailorContent.dailyRundownReinforcement ? (
						<TooltipItem
							id={"dailyRundownTooltip"}
							text={this.props.tailorContent.dailyRundownReinforcement}
						/>
					) : null}
				</CardHeader>
				<div style={{ padding: 10 }}>
					{this.props.tailorContent.placeholder}
				</div>
			</Card>
		);
	}
}
