import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import globalStore from "../GlobalStore";
import { observer } from "mobx-react";
import { Tailor } from "../Tailor";
import Loading from "../Loading";

export default observer(
	class DeviceConnections extends Component {
		constructor(props) {
			super(props);

			this.state = { tailorContent: null };
		}

		async componentDidMount() {
			this.setState({
				tailorContent: await Tailor(11, {
					limitToElements: ["Device Connections"]
				})
			});
		}

		closeModal() {
			globalStore.deviceConnectionsOpen = false;
		}

		render() {
			return (
				<Modal
					isOpen={globalStore.deviceConnectionsOpen}
					toggle={this.closeModal}
				>
					<ModalHeader toggle={this.closeModal}>Device Connections</ModalHeader>

					{this.state.tailorContent ? (
						<ModalBody className="text-center">
							<p>{this.state.tailorContent.deviceConnections}</p>
							<a
								href="https://skills-store.amazon.com/deeplink/dp/B076TJKC6D?deviceType=app&share&refSuffix=ss_copy"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									alt="Amazon Alexa Logo"
									src="/alexa.png"
									style={{ width: "100%", maxWidth: 300 }}
								/>
							</a>
						</ModalBody>
					) : (
						<ModalBody>
							<Loading />
						</ModalBody>
					)}
					<ModalFooter>
						<Button color="secondary" onClick={this.closeModal}>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			);
		}
	}
);
