import { observer } from "mobx-react";
import { observable } from "mobx";
import { Component } from "react";
import { Radio } from "@rmwc/radio";
import { Checkbox } from "@rmwc/checkbox";
import React from "react";
import Select from "react-select";
import autoBind from "react-autobind";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Tooltip from "rc-tooltip";
import { Row, Col, Container } from "reactstrap";
import { Button } from "@rmwc/button";
import Mousetrap from "mousetrap";

const QuestionContainer = observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.pageStore = new observable({
				selectedAnswer: null,
				otherTextField: "",
				selectedAnswerDropdown: null,
				checkedBoxes: []
			});
		}

		save() {
			this.props.save(
				this.pageStore,
				this.props.attribute,
				this.props.answers,
				this.props.privateQuestion
			);
		}

		render() {
			let answers;
			if (this.props.answers === null) {
				answers = (
					<OtherAnswer
						openEndedQuestion={true}
						pageStore={this.pageStore}
						questionNum={this.props.questionNum}
						goBack={this.props.goBack}
						save={this.save}
						attribute={this.props.attribute}
					/>
				);
			} else if (this.props.answers[0].checkboxes) {
				answers = (
					<CheckboxAnswers
						answers={this.props.answers[0].checkboxes}
						pageStore={this.pageStore}
						save={this.save}
						goBack={this.props.goBack}
					/>
				);
			} else if (this.props.answers[0].value === "min") {
				let unit;

				if (this.props.question.includes("feet and inches")) {
					unit = "height";
				} else if (this.props.question.includes("in pounds")) {
					unit = "lbs";
				}

				answers = (
					<SliderAnswers
						answers={this.props.answers}
						pageStore={this.pageStore}
						unit={unit}
						goBack={this.props.goBack}
						save={this.save}
						attribute={this.props.attribute}
					/>
				);
			} else if (this.props.answers.length > 7) {
				answers = (
					<SelectAnswers
						answers={this.props.answers}
						pageStore={this.pageStore}
						questionNum={this.props.questionNum}
						goBack={this.props.goBack}
						save={this.save}
						attribute={this.props.attribute}
					/>
				);
			} else {
				answers = (
					<RadioAnswers
						answers={this.props.answers}
						pageStore={this.pageStore}
						questionNum={this.props.questionNum}
						goBack={this.props.goBack}
						save={this.save}
						attribute={this.props.attribute}
					/>
				);
			}

			return (
				<Row>
					<Col>
						<p dangerouslySetInnerHTML={{ __html: this.props.question }} />
						{answers}
					</Col>
				</Row>
			);
		}
	}
);

const RadioAnswers = observer(
	class extends Component {
		componentDidMount() {
			this.props.answers.map((answer, index) => {
				return Mousetrap.bind(`${index + 1}`, () => {
					this.props.pageStore.selectedAnswer = answer.value.toString();
				});
			});
		}

		componentWillUnmount() {
			this.props.answers.map((answer, index) => {
				return Mousetrap.unbind(`${index + 1}`);
			});
		}

		render() {
			return (
				<div>
					{this.props.answers &&
						this.props.answers.map(answer => {
							return (
								<Row key={answer.value}>
									<Col>
										<Radio
											value={answer.value.toString()}
											checked={
												this.props.pageStore.selectedAnswer ===
												answer.value.toString()
											}
											onChange={() => {
												this.props.pageStore.selectedAnswer = answer.value.toString();
											}}
											id={answer.value.toString()}
										>
											<label
												style={{
													marginBottom: 0,
													paddingTop: 5
												}}
												htmlFor={answer.value.toString()}
											>
												{answer.label}
											</label>
										</Radio>
									</Col>
								</Row>
							);
						})}

					<OtherAnswer
						pageStore={this.props.pageStore}
						questionNum={this.props.questionNum}
						goBack={this.props.goBack}
					/>
					<SaveButton
						save={this.props.save}
						disabled={!this.props.pageStore.selectedAnswer}
					/>
				</div>
			);
		}
	}
);

const SelectAnswers = observer(
	class extends Component {
		render() {
			return (
				<Container>
					<Row style={{ marginBottom: 5 }}>
						<Col>
							<Select
								value={this.props.pageStore.selectedAnswerDropdown}
								onChange={selectedOption => {
									this.props.pageStore.selectedAnswerDropdown = selectedOption;

									if (selectedOption.value) {
										this.props.pageStore.selectedAnswer = selectedOption.value;
									} else {
										this.props.pageStore.selectedAnswer = null;
									}
								}}
								options={this.props.answers}
								goBack={this.props.goBack}
								autoFocus={true}
							/>
						</Col>
					</Row>
					<OtherAnswer
						pageStore={this.props.pageStore}
						questionNum={this.props.questionNum}
					/>
					<SaveButton
						save={this.props.save}
						disabled={!this.props.pageStore.selectedAnswer}
					/>
				</Container>
			);
		}
	}
);

const OtherAnswer = observer(
	class extends Component {
		componentDidMount() {
			Mousetrap.bind("enter", () => {
				if (
					this.props.openEndedQuestion &&
					this.props.pageStore.otherTextField
				) {
					this.props.save();
				}
			});
		}

		componentWillUnmount() {
			Mousetrap.unbind("enter");
		}

		render() {
			return this.props.pageStore.selectedAnswer === "other" ||
				this.props.openEndedQuestion ? (
				<Row>
					<Col className="justify-content-center">
						<div className="form-group">
							{this.props.openEndedQuestion ? null : (
								<label>Please describe:</label>
							)}
							<input
								autoFocus={true}
								className="form-control mousetrap"
								type="text"
								value={this.props.pageStore.otherTextField}
								onChange={evt => {
									this.props.pageStore.otherTextField = evt.target.value;
								}}
							/>
						</div>
						<div className="w-100 d-none d-md-block" />
						<Button
							raised
							onClick={this.props.save}
							disabled={
								!(
									this.props.openEndedQuestion &&
									this.props.pageStore.otherTextField
								)
							}
						>
							Save
						</Button>
					</Col>
				</Row>
			) : null;
		}
	}
);

const SliderAnswers = observer(
	class extends Component {
		render() {
			const Handle = Slider.Handle;

			const handle = props => {
				const { value, dragging, questionNum, ...restProps } = props;
				let overlay = value;
				if (this.props.unit === "height") {
					const feet = Math.floor(value / 12);
					const inches = value % 12;

					overlay = `${feet}' ${inches}"`;
				} else if (this.props.unit === "lbs") {
					overlay = `${value} lbs`;
				}

				return (
					<Tooltip
						prefixCls="rc-slider-tooltip"
						overlay={overlay}
						visible={true}
						placement="top"
						key={questionNum}
					>
						<Handle value={value} {...restProps} />
					</Tooltip>
				);
			};

			return (
				<div className="justify-content-center">
					<Slider
						style={{ marginBottom: 10, marginTop: 40 }}
						min={parseInt(this.props.answers[0].label, 10)}
						max={parseInt(this.props.answers[1].label, 10)}
						value={
							this.props.pageStore.selectedAnswer
								? this.props.pageStore.selectedAnswer
								: Math.round(
										(parseInt(this.props.answers[0].label, 10) +
											parseInt(this.props.answers[1].label, 10)) /
											2
								  )
						}
						onChange={value => {
							this.props.pageStore.selectedAnswer = value;
						}}
						handle={handle}
					/>

					<SaveButton
						save={this.props.save}
						disabled={!this.props.pageStore.selectedAnswer}
					/>
				</div>
			);
		}
	}
);

const CheckboxAnswers = observer(
	class extends Component {
		render() {
			return (
				<Container>
					{this.props.answers.map(answer => {
						return (
							<Row key={answer.value}>
								<Checkbox
									label={answer.label}
									checked={this.props.pageStore.checkedBoxes.includes(
										answer.value
									)}
									onChange={() => {
										if (
											this.props.pageStore.checkedBoxes.includes(answer.value)
										) {
											const index = this.props.pageStore.checkedBoxes.indexOf(
												answer.value
											);
											if (index !== -1) {
												this.props.pageStore.checkedBoxes.splice(index, 1);
											}
										} else {
											this.props.pageStore.checkedBoxes.push(answer.value);
										}
									}}
								/>
								{answer.otherValue &&
								this.props.pageStore.checkedBoxes.includes(answer.value) ? (
									<OtherAnswer
										alwaysShow={true}
										pageStore={this.props.pageStore}
									/>
								) : null}
							</Row>
						);
					})}
					<SaveButton
						save={this.props.save}
						disabled={this.props.pageStore.checkedBoxes.length === 0}
					/>
				</Container>
			);
		}
	}
);

class SaveButton extends Component {
	componentDidMount() {
		Mousetrap.prototype.stopCallback = function(e, element, combo) {
			return false;
		};

		Mousetrap.bind("enter", () => {
			if (!this.props.disabled) {
				this.props.save();
			}
		});
	}

	componentWillUnmount() {
		Mousetrap.unbind("enter");

		//reset the stop callback function to the default in case we use mousetrap
		//in other places
		Mousetrap.prototype.stopCallback = function(e, element, combo) {
			if ((" " + element.className + " ").indexOf(" mousetrap ") > -1) {
				return false;
			}

			// stop for input, select, and textarea
			return (
				element.tagName === "INPUT" ||
				element.tagName === "SELECT" ||
				element.tagName === "TEXTAREA" ||
				(element.contentEditable && element.contentEditable === "true")
			);
		};
	}

	render() {
		return (
			<Row style={{ marginTop: 10 }}>
				<Col className="text-center">
					<Button
						type="submit"
						raised
						onClick={this.props.save}
						disabled={this.props.disabled}
					>
						Save
					</Button>
				</Col>
			</Row>
		);
	}
}
export { QuestionContainer };

// return (
// 	<Slider
// 		value={
// 			this.props.pageStore.selectedAnswer
// 				? this.props.pageStore.selectedAnswer
// 				: Math.round(
// 						(parseInt(this.props.answers[0].label, 10) +
// 							parseInt(this.props.answers[1].label, 10)) /
// 							2
// 				  )
// 		}
// 		onChange={evt => {
// 			this.props.pageStore.selectedAnswer = evt.detail.value;
// 		}}
// 		onInput={evt => {
// 			this.props.pageStore.selectedAnswer = evt.detail.value;
// 		}}
// 		min={parseInt(this.props.answers[0].label, 10)}
// 		max={parseInt(this.props.answers[1].label, 10)}
// 		step={1}
// 		discrete
// 	/>
// );
