import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "@material/ripple/dist/mdc.ripple.css";
import '@material/button/dist/mdc.button.css';
import '@material/card/dist/mdc.card.css';
import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/elevation/dist/mdc.elevation.css';
import '@material/switch/dist/mdc.switch.css';
import '@material/radio/dist/mdc.radio.css';
import '@material/toolbar/dist/mdc.toolbar.css';
import '@material/linear-progress/dist/mdc.linear-progress.css';
import '@rmwc/icon/icon.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';
import "./style/myhealthyday.css";


ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
