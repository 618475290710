import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

export default class Loading extends Component {
	constructor(props) {
		super(props);

		this.state = {
			statusText: null
		};

		this.timeouts = [];
	}

	componentDidMount() {
		this.timeouts.push(
			setTimeout(() => {
				this.setState({
					statusText: "Almost there..."
				});
			}, 5000)
		);

		this.timeouts.push(
			setTimeout(() => {
				this.setState({
					statusText: "Getting closer..."
				});
			}, 10000)
		);

		this.timeouts.push(
			setTimeout(() => {
				this.setState({ statusText: "Just a bit more..." });
			}, 15000)
		);

		this.timeouts.push(
			setTimeout(() => {
				this.setState({ statusText: "Something might've gone wrong..." });
			}, 60000)
		);

		this.timeouts.push(
			setTimeout(() => {
				this.setState({ statusText: "Something's definitely wrong..." });
			}, 120000)
		);
	}

	componentWillUnmount() {
		this.timeouts.map(timeout => {
			return clearTimeout(timeout);
		});
	}

	render() {
		let style = {};
		let iFrameStyle = {};

		if (this.props.style) {
			style = this.props.style;
		} else if (this.props.small) {
			style = {};
			iFrameStyle = { width: 150, height: 150 };
		} else {
			style = {
				paddingTop: 25,
				paddingBottom: 25
			};
		}

		return (
			<Container className="loading" style={style}>
				<Row>
					<Col>
						<iframe
							title="Loading..."
							allowtransparency="true"
							src="/loading.html"
							style={{
								overflow: "hidden",
								border: "none",
								height: 260,
								maxWidth: "100%",
								...iFrameStyle
							}}
						/>
					</Col>
				</Row>

				{!this.props.small ? (
					<h5
						className="statusText"
						style={{
							marginTop: 10,
							fontStyle: "italic",
							color: this.state.statusText ? "inherit" : "transparent"
						}}
					>
						{this.state.statusText ? this.state.statusText : "Loading..."}
					</h5>
				) : null}
			</Container>
		);
	}
}
