import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import API from "../../API";
import { Redirect } from "react-router";
import { Button } from "@rmwc/button";
import Visibility from "visibilityjs";

export default class CheckInButton extends Component {
	constructor(props) {
		super(props);

		this.state = { sessionCount: null, redirect: null };
	}

	componentDidMount() {
		Visibility.change((e, state) => {
			if (state === "visible") {
				API("/getData", "POST", { keys: ["sessionCount"] }, data => {
					//if making the tab active and the session count doesn't match what's currently showing, reload the page which will trigger the master routing function
					if (
						this.state.sessionCount &&
						data.data.sessionCount !== this.state.sessionCount
					) {
						window.location = "/";
					}
				});
			}
		});

		API("/getData", "POST", { keys: ["sessionCount"] }, data => {
			if (data.data.sessionCount === "10") {
				API("/reportData", "GET", null, data => {
					if (data.reportData) {
						setTimeout(() => {
							this.setState({ redirect: "/generateReport" });
						}, 500);
					}
				});
			}
			this.setState({ sessionCount: data.data.sessionCount });
		});
	}

	nextSession = () => {
		if (this.state.sessionCount === "10") {
			this.setState({ redirect: "/generateReport" });
		} else {
			API(
				"/data",
				"PUT",
				{
					data: {
						sessionCount: parseInt(this.state.sessionCount) + 1
					},
					sessionData: { sessionStarted: "true" }
				},
				() => {
					this.setState({ redirect: "/sessionIntroduction" });
				}
			);
		}
	};

	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} />;
		}
		return this.state.sessionCount ? (
			<Row>
				<Col>
					<Button
						dense
						onClick={this.nextSession}
						raised
						style={{
							backgroundColor: "#FFB100",
							color: "#1E487F",
							fontSize: 16,
							height: 48,
							borderRadius: 10,
							marginTop: 5,
							fontWeight: 700
						}}
					>
						{this.state.sessionCount === "10"
							? "Generate Report"
							: "Check In Now"}
					</Button>
				</Col>
			</Row>
		) : null;
	}
}
